<template>
  <div class="trigger-container" @click="!loading && $emit('showDetail', $event)" title="Show detail">
    <div class="icon" v-if="currentWeather.weatherCode">
      <v-img :src="getImageUrl(currentWeather.weatherCode)"
             :title="currentWeather.weatherDescription"
             :alt="currentWeather.weatherDescription"
             width="36" height="36">
      </v-img>
    </div>
    <div class="temperature">{{ currentWeather.currentT }} °C</div>

    <div v-if="loading" style="padding: 0 6px;">
      <v-progress-circular
          color="white"
          :size="24"
          indeterminate
      />
    </div>

    <v-btn v-if="!loading && historicalAvailable"
        v-else
        icon
        color="white"
        @click.stop="$emit('showDetail')"
        title="Show detail">
      <v-icon>{{ icons.add }}</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import {CurrentWeather} from '@/weather/weather-info.domain'
import { PropType } from 'vue'
import {icons} from "@utils/icons.utils";

export default {
  name: 'WeatherInfoTrigger',
  components: {},
  props: {
    currentWeather: {
      type: Object as PropType<CurrentWeather>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false
    },
    historicalAvailable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icons() {
      return icons;
    }
  },
  methods: {
    getImageUrl(weatherCode: string) {
      return require(`../assets/icons/weather/icon-${weatherCode}.png`);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './weather-colors';

.trigger-container {
  background: $blue;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 8px 12px;
  &:hover {
    background: $blue-15;
  }
}

.temperature {
  font-size: 1.2rem;
}
</style>
