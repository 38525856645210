<template>
  <div>
    <v-text-field :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N2.ADVICE_N2"
                  :rules="adviceN2Rule"
                  v-model="value.adviceN2"
                  :disabled="disabled"></v-text-field>

    <v-text-field :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N2.ADVICE_N3"
                  :rules="adviceN3Rule"
                  v-model="value.adviceN3"
                  :disabled="disabled"></v-text-field>

    <v-text-field :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N2.N_CONTENT_FERTILIZER"
                  :rules="nitrogenContentRule"
                  v-model="value.n_ContentFertilizer"
                  :disabled="disabled"></v-text-field>

    <v-checkbox :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N2.HOMOGENIZE"
                v-model="value.homogenize"
                :disabled="disabled"></v-checkbox>

    <v-text-field :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N2.ADD_N3"
                  :rules="addN3percentageRule"
                  v-model="value.addN3Percentage"
                  :disabled="disabled"></v-text-field>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSoilTypes } from "@/apdtAdvice/Advice.service";
import { AdviceFertilizingN2Form, AdviceLimingForm } from "@/apdtAdvice/forms/forms.domain";
import {integerRule, maxRule, minRule, percentageRule} from "@/apdtAdvice/forms/InputRules";

export default {
  name: "AdviceFertilizingN2Input.form-item",
  components: {},
  props: {
    disabled: Boolean,
    value: {
      default: new AdviceFertilizingN2Form(),
      type: AdviceFertilizingN2Form
    }
  },
  data() {
    return {
      options: [],
      adviceN2Rule: [ integerRule()],
      adviceN3Rule: [ integerRule()],
      nitrogenContentRule: percentageRule,
      addN3percentageRule: [integerRule(), minRule(0), maxRule(50)]

    };
  },
  async mounted() {
    // getSoilTypes().then(value => this.options = value.map(item => ({value: item, label: this.translations.APDT_ADVICE.SOIL_TYPES[item]})));
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    })

  },
  methods: {},
  watch: {
    value: {
      handler: function(val, oldVal) {
        this.$emit("input", this.value);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
