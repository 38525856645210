// INTERNAL
import {BaleServiceVehicleTypeEnum, BinTypeEnum, LanguageEnum} from 'shared-frontend';
import {Translations} from '@translations/translations.type';

const EN_TRANSLATIONS: Translations = {
    GENERAL: {
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
        BACK: 'Back',
        UPLOAD: 'Upload',
        DELETE: 'Delete',
        EDIT: 'Edit',
        STOP: 'Stop',
        OR: 'Or',
        EXPORT: 'Export',
        CLOSE: 'Close',
        RETRY: 'Retry',
        COMING_SOON: 'Coming soon',
        REQUIRED: 'Required field'
    },
    MENU: {
        SETTINGS: 'Settings',
        LOG_OUT: 'Log out',
        BACK_TO_MY_FARM: 'Back to my farm',
        TO_ADMIN_PAGE: 'To admin',
        TO_PORTAL: 'Back to portal'
    },
    HEADER_TICKER: {
        TEAM_IDENTIFIER_LABEL: 'Team'
    },
    MESSAGING: {
        TITLE: 'Messaging',
        SEND_MESSAGE: 'Send message',
        SEARCH: 'Search messages',
        TABLE: {
            HEADERS: {
                CREATED_ON: 'Sent on',
                MESSAGE: 'Message',
                CREATED_BY: 'Sender'
            }
        },
        FORM: {
            MESSAGE_LABEL: 'Enter message',
            MESSAGE_ERROR_LENGTH: 'Max 250 characters'
        },
        UNKNOWN_USER: 'Unknown',
        DISABLED: 'You can not send messages in stopped teams'
    },
    REPORTS: {
        TITLE: 'Reports',
        NO_REPORTS:
            'No reports available at this time. The field report becomes available when you stop the team. As soon as you add service vehicles to the team you will also be able to download bin reports',
        YEAR_REPORT: {
            TITLE: 'Year Report'
        },
        FIELD_REPORT: {
            TITLE: 'Field Report'
        },
        BIN_REPORTS: {
            TITLE: 'Bin Reports'
        }
    },
    TABS: {
        OVERVIEW: 'Overview',
        WORKFORCE: 'Workforce & vehicles',
        ABOUT: 'About'
    },
    ERRORS: {
        GENERAL_TITLE: 'Error',
        GENERAL: 'Sorry, something went wrong, please try again later...'
    },
    GEOLOCATION_FAILED: {
        TITLE: 'Retrieving browser location failed!',
        MESSAGE: 'Sorry! There was an issue retrieving your browser location.'
    },
    TEAM: {
        TEAM_NAME: 'Team name',
        GROWER: 'Grower',
        FARM: 'Farm',
        FIELD: 'Field',
        YEAR: 'Year',
        CROP: 'Crop',
        OPERATOR: 'Operator',
        FARM_MANAGER: 'Farm manager',
        UNKNOWN: 'Unknown'
    },
    TEAM_INFO: {
        TITLE: 'Team info'
    },
    NO_TEAM: 'No team selected',
    NO_TEAMS: 'No teams available',
    OVERVIEW: {
        TEAM_NAME_LABEL: 'Team: ',
        LABELS: {
            YEAR_UNKNOWN: 'Year unknown',
            GROWER_UNKNOWN: 'Grower unknown',
            FARM_UNKNOWN: 'Farm unknown',
            FIELD_UNKNOWN: 'Field unknown',
            CROP_UNKNOWN: 'Crop unknown',
            TEAM_UNKNOWN: 'Team unknown'
        },
        STOP_TEAM: 'Stop team',
        NO_TEAMS: {
            TITLE: 'You have no field teams!',
            PARAGRAPH_1:
                'You can create a team by going to the mobile app (Android or iOS), logging in with the same email and password and creating a new team.',
            PARAGRAPH_2:
                'You can create a team by going to the mobile app (Android or iOS), logging in with the same email and password and creating a new team.'
        },
        STOPPED: 'stopped'
    },
    ABOUT: {
        PARAGRAPH_1: 'The Harvest Coordination APP is a first in class application that valorizes your telematic data.',
        PARAGRAPH_2:
            'In the first place it is a big help to improve the Coordination between your people and machines in the field and on the road. In the second place it provides you a unique geo-traceability tool of your crops and crop nutrition parameters.',
        PARAGRAPH_3:
            'The APP is available as a FREE version but to use the full feature set you need the Harvest Coordination Device (Part Number 12376543) which needs to be installed in your combine(s). With the device you will enjoy the advantages of using your big data in an efficient way..',
        PARAGRAPH_4:
            "Important! In order to create a gate, you should click with your cursor on the map in the 'Overview' tab and press for 3 seconds interrupted. To then delete that gate, it suffices to click again on the Gate icon on the map."
    },
    DASHBOARD: {
        ACTIVE_TEAMS: 'Active teams',
        WORKFORCE_USERS: 'Workforce',
        WORKFORCE_USERS_INFO:
            'Your workforce consists of all persons who are able to work for you. These persons can enter any of your teams in the mobile app (and become a field team member). If you add a person, he will receive an email informing him of this event.',
        INACTIVE: 'inactive',
        WORKFORCE_SERVICE_VEHICLES: 'My service vehicles',
        WORKFORCE_SERVICE_VEHICLES_INFO:
            'The service vehicles that you own (as Farm Manager) or that you frequently use. Any field team member can add any of these service vehicles into an active field team.',
        BIN_CAPACITY_UNIT: 'm³',
        WORKFORCE_ARABLE_VEHICLES: 'My arable vehicles',
        WORKFORCE_ARABLE_VEHICLES_INFO:
            'The arable vehicles (Combine Harvester, SPFH, Baler) that you own (as Farm Manager) or that you frequently use. Any field team member can add any of these vehicles into an active field team.',
        WORKFORCE_GROWERS: 'Growers',
        WORKFORCE_GROWERS_INFO:
            'The growers of the fields that you harvested. You can invite them via their email address and they will then be able to see the activity and reporting about their fields only.',
        ADD: 'Add',
        NO_WORKFORCE: {
            PARAGRAPH_1: 'There are 0 persons in your workforce',
            PARAGRAPH_2:
                'Please add your first workforce member, so he/she will be able to join one of your teams in the mobile app'
        },
        NO_FARM_BINS: {
            PARAGRAPH_1: 'You have 0 service vehicels saved.',
            PARAGRAPH_2: 'Please add your first service vehicle so it can later be added to a field team'
        },
        NO_FARM_ARABLE_VEHICLES: {
            PARAGRAPH_1: 'You have 0 arable vehicles saved.',
            PARAGRAPH_2: 'Please create a team in the mobile app and connec to your first arable vehicles'
        },
        NO_GROWERS: {
            PARAGRAPH_1: 'You have no teams with "grower" specified!',
            PARAGRAPH_2:
                'When you create (or edit an existing team) with the "Grower" field specified, these Grower names will become available so you can invite them!'
        }
    },
    TEAM_MEMBERS: {
        COMBINES: 'Combines',
        SPFHS: 'SPFHs',
        BALERS: 'Balers',
        SERVICE_VEHICLES: 'Service vehicles',
        USERS: 'Users',
        NO_COMBINES_IN_TEAM: 'No combines in team',
        NO_BALERS_IN_TEAM: 'No balers in team',
        NO_BINS_IN_TEAM: 'No service vehicles in team',
        NO_USERS_IN_TEAM: 'No users in team',
        REMOVED: 'removed'
    },
    TEAM_PAGE: {
        FIELD_FINISHED_AT: 'Predicted field finished at:',
        TIME_UNTIL_BIN_FULL: 'Time until bin full',
        LAST_SEEN: 'last seen',
        ESTIMATED_TOTAL_BALE_COUNT: 'Predicted Bale Count:',
        ESTIMATED_BINS_LEFT: 'Remaining bins needed:',
        WEIGHT_TO_FINISH: 'Remaining weight to harvest:',
        VOLUME_TO_FINISH: 'Remaining volume to harvest:',
        VOLUME_WEIGHT_TO_FINISH: 'Remaining volume/weight:'
    },
    CONTEXT_MENU: {
        DOWNLOAD_BIN_REPORT_PDF: 'Download bin report PDF',
        DOWNLOAD_BIN_REPORT_CSV: 'Download bin report CSV',
        HIDE_FIELD_COVERAGE: 'Hide field coverage',
        SHOW_FIELD_COVERAGE: 'Show field coverage',
        SHOW_INFORMATION: 'Show information',
        SHOW_ESP_CAMS: 'Show real time view'
    },
    TELEMETRY: {
        TITLE: 'Instantaneous information',
        COLUMN_VALUE_TITLE: 'Variable',
        COLUMN_VARIABLE_TITLE: 'Value',
        loading: 'Fetching data...',
        notAvailable: 'Sorry, no data available at the moment!'
    },
    MODALS: {
        ADD_USER: {
            TITLE: 'Add workforce member',
            FORM: {
                FIRST_NAME_LABEL: 'First name',
                FIRST_NAME_ERROR_REQUIRED: 'First name must be filled in',
                FIRST_NAME_ERROR_LENGTH: 'First name cannot be longer than 50 characters',
                LAST_NAME_LABEL: 'Last name',
                LAST_NAME_ERROR_REQUIRED: 'Last name must be filled in',
                LAST_NAME_ERROR_LENGTH: 'Last name cannot be longer than 50 characters',
                EMAIL_LABEL: 'Email',
                EMAIL_ERROR_VALID: 'Email must be a valid email',
                PHONE_LABEL: 'Phone number (eg. +32494...)',
                PHONE_ERROR_VALID: 'Phone number is invalid',
                CONFIRM_BUTTON: 'Add'
            }
        },
        LINK_EDIT_GROWER: {
            TITLE_LINK: 'Link grower',
            TITLE_EDIT: 'Edit grower',
            FORM: {
                GROWER_NAME: 'Grower name',
                FIRST_NAME_LABEL: 'First name',
                FIRST_NAME_ERROR_REQUIRED: 'First name must be filled in',
                FIRST_NAME_ERROR_LENGTH: 'First name cannot be longer than 50 characters',
                LAST_NAME_LABEL: 'Last name',
                LAST_NAME_ERROR_REQUIRED: 'Last name must be filled in',
                LAST_NAME_ERROR_LENGTH: 'Last name cannot be longer than 50 characters',
                EMAIL_LABEL: 'Email',
                EMAIL_ERROR_VALID: 'Email must be a valid email',
                EMAIL_ERROR_SAME_AS_FARM_MANAGER:
                    'You cannot invite yourself as grower! Please use another email address.',
                CONFIRM_BUTTON_LINK: 'Link grower',
                CONFIRM_BUTTON_EDIT: 'Edit grower'
            }
        },
        CONFIRM_UNLINK_GROWER: {
            TITLE: 'Unlink grower',
            MESSAGE:
                "Are you sure you want to unlink this grower? He will no longer have access to his teams' data in the portal"
        },
        ADD_BIN: {
            TITLE: 'Add bin',
            FORM: {
                NAME_LABEL: 'Name',
                NAME_ERROR_REQUIRED: 'Name must be filled in',
                NAME_ERROR_LENGTH: 'Name cannot be longer than 50 characters',
                CAPACITY_LABEL: 'Capacity (in m³)',
                CAPACITY_ERROR_REQUIRED: 'Capacity must be filled in.',
                CAPACITY_ERROR_POSITIVE: 'Capacity must be a positive number',
                BIN_TYPE_LABEL: 'Type',
                BIN_TYPES: {
                    [BinTypeEnum.NORMAL_BIN]: 'Normal bin',
                    [BinTypeEnum.MOTHER_BIN]: 'Mother bin',
                    [BinTypeEnum.TRUCK]: 'Truck',
                    [BinTypeEnum.CHASER_BIN]: 'Chaser bin'
                },
                BIN_COLOR_LABEL: 'color',
                CONFIRM_BUTTON: 'Add'
            }
        },
        ADD_BALE_SERVICE_VEHICLE: {
            TITLE: 'Add bale wrapper or pickup',
            FORM: {
                NAME_LABEL: 'Name',
                NAME_ERROR_REQUIRED: 'Name must be filled in',
                NAME_ERROR_LENGTH: 'Name cannot be longer than 50 characters',
                BALE_SERVICE_VEHICLE_TYPE_LABEL: 'Type',
                BALE_SERVICE_VEHICLE_TYPES: {
                    // @ts-ignore
                    [BaleServiceVehicleTypeEnum.WRAPPER]: 'Wrapper',
                    // @ts-ignore
                    [BaleServiceVehicleTypeEnum.PICKUP]: 'Pickup'
                },
                BALE_SERVICE_VEHICLE_COLOR_LABEL: 'Color',
                CONFIRM_BUTTON: 'Add'
            }
        },
        ADD_SERVICE_VEHICLE: {
            TITLE: 'Add service vehicle',
            BIN: 'bin',
            BALE_SERVICE_VEHICLE: 'bale wrapper or pickup'
        },
        CONFIRM_DELETE_USER: {
            TITLE: 'Delete user',
            MESSAGE: 'Are you sure you want to delete this user from your workforce?'
        },
        CONFIRM_DELETE_BIN: {
            TITLE: 'Delete service vehicle',
            MESSAGE: 'Are you sure you want to delete this service vehicle from your workforce?'
        },
        CONFIRM_DELETE_BALE_SERVICE_VEHICLE: {
            TITLE: 'Delete service vehicle',
            MESSAGE: 'Are you sure you want to delete this service vehicle from your workforce?'
        },
        CREATE_GATE: {
            TITLE: 'Create gate',
            MESSAGE: 'Are you sure you want to create a gate at this location?'
        },
        DELETE_GATE: {
            TITLE: 'Delete gate',
            MESSAGE: 'Are you sure you want to delete the gate at this location?'
        },
        COMING_SOON: {
            TITLE: 'Coming soon',
            MESSAGE: 'This functionality is currently under development'
        },
        SETTINGS: {
            TITLE: 'Settings',
            FORM: {
                COMBINE_REPORT_TYPE_LABEL: 'Report type (Combine)',
                SPFH_REPORT_TYPE_LABEL: 'Report type (SPFH)',
                BALER_REPORT_TYPE_LABEL: 'Report type (Baler)',
                WARN_PROXIMITY_METERS_LABEL: 'Warning vehicle proximity (in m)',
                LANGUAGE_LABEL: 'Language',
                LANGUAGE_ERROR_REQUIRED: 'Language must be filled in',
                CONFIRM_BUTTON: 'Save settings',
                BRANDING_LABEL: 'Branding',
                LOADING: 'Retrieving settings from server',
                NOT_AVAILABLE:
                    "Settings couldn't be retrieved from server, please check your internet connection or try again\n" +
                    '                later.',
                APDT_CREDENTIALS_LABEL: 'APDT credentials',
                CONSENT_LABEL: 'Consent'
            }
        },
        EDIT_USER: {
            TITLE: 'Edit workforce member',
            FORM: {
                EMAIL_LABEL: 'Email address',
                TELEPHONE_NUMBER_LABEL: 'Telephone number',
                ACTIVE_INACTIVE_LABEL: 'Active',
                ACTIVE_INACTIVE_INFO_TEXT:
                    'An inactive user is not able to use the mobile application. He cannot join any of your field teams.',
                AUTHORITY_LABEL: 'Authority',
                AUTHORITY_INFO_TEXT:
                    "A user with 'authority' is able to see detailed machine and crop parameters. He/she is also able to remove other field team members from the team.",
                CONFIRM_BUTTON: 'Save'
            }
        },
        STOP_TEAM: {
            TITLE: (teamName: string) => `Stop team: ${teamName}`,
            MESSAGE:
                'Are you sure that you want to stop this team? This action cannot be undone. The field report will be generated after the team is stopped.'
        },
        DELETE_TEAM: {
            TITLE: (teamName: string) => `Delete team: ${teamName}`,
            MESSAGE: 'Are you sure that you want to delete this team? This action cannot be undone.'
        },
        COMBINE_FUEL_LOW: {
            TITLE: 'Combine fuel tank level low!',
            MESSAGE: (fuelTankLevel: number, combineName: string) =>
                `Attention! The fuel tank for ${combineName} is at ${fuelTankLevel}%`
        },
        SPFH_FUEL_LOW: {
            TITLE: 'SPFH fuel tank level low!',
            MESSAGE: (fuelTankLevel: number, spfhName: string) =>
                `Attention! The fuel tank for ${spfhName} is at ${fuelTankLevel}%`
        },
        BALER_FUEL_LOW: {
            TITLE: 'Baler fuel tank level low!',
            MESSAGE: (fuelTankLevel: number, balerName: string) =>
                `Attention! The fuel tank for ${balerName} is at ${fuelTankLevel}%`
        },
        CONFIRM_DELETE_FARM_BOUNDARY: {
            TITLE: 'Delete boundary',
            MESSAGE: 'Are you sure you want to delete this boundary?'
        },
        EDIT_TEAM_INFO: {
            TITLE: 'Edit team info',
            CROP_ERROR_LENGTH: 'Crop cannot be longer than 50 characters',
            OPERATOR_ERROR_LENGTH: 'Operator cannot be longer than 50 characters',
            FARM_ERROR_LENGTH: 'Farm cannot be longer than 50 characters',
            FIELD_ERROR_LENGTH: 'Field cannot be longer than 50 characters',
            GROWER_ERROR_LENGTH: 'Grower cannot be longer than 50 characters',
            NAME_ERROR_REQUIRED: 'Name must be filled in',
            YEAR_ERROR_REQUIRED: 'Year must be filled in',
            YEAR_ERROR_FUTURE: 'Year cannot be in the future',
            YEAR_ERROR_MIN_YEAR: 'Year must be at least 2000'
        },
        CONFIRM_IMPORT_FROM_EVO_PORTAL: {
            TITLE: 'Import from EVO portal',
            MESSAGE:
                'Do you want to import boundaries from the EVO portal? This will overwrite existing imported boundaries.',
            LOADING_MESSAGE: 'The import has been started. You can safely close this modal.'
        },
        CONFIRM_UPLOAD_TO_EVO_PORTAL: {
            TITLE: 'Upload to EVO portal',
            CONFIRM: (target: string) => `Do you want to upload this ${target} to the EVO portal?`,
            EXPORTING: (target: string) => `Uploading ${target}...`,
            FAILURE: (target: string) => `Failed to upload ${target}.`,
            SUCCESS: (target: string) =>
                `The upload was successful. In order to use the ${target}, you will have to process the file with the name below in the EVO portal itself.`,
            SHOW_HOW_TO_PROCESS: 'Show how to process',
            HOW_TO_PROCESS: {
                STEP_1: 'Go to the EVO Portal',
                STEP_2: 'Click on the gear icon.',
                STEP_3: 'Click on the <strong>Data Files</strong> tab.',
                STEP_4: (uploadedFileName: string) =>
                    `Click on the file with name <strong>${uploadedFileName}</strong>.`,
                STEP_5: 'Click on <strong>Process</strong>.',
                STEP_6: 'Follow the steps in the wizard.'
            }
        },
        MARKER: {
            TITLE: {
                PICTURE: 'View picture',
                OBSTACLE: 'View obstacle'
            },
            NAME: {
                LABEL: 'Name'
            },
            LOCATION: {
                LABEL: 'Location'
            },
            CREATED_ON: {
                LABEL: 'Created on'
            },
            NO_PICTURE: 'No picture has been taken'
        },
        ESP_CAMS: {
            TITLE: 'Real time view',
            NO_ESP_CAMS: 'No active cameras',
            FORM: {
                ESP_CAM: {
                    LABEL: 'Camera',
                    ITEM(mac: string): string {
                        return `Camera ${mac}`;
                    }
                }
            },
            ERROR_GET_ESP_CAMS: 'Failed to retrieve cameras'
        }
    },
    MESSAGES: {
        URGENT_UNLOAD_NEEDED: 'Urgent unload needed',
        UNLOADING_IMMINENT: 'Unloading imminent',
        DELAY: 'I will be late in the field',
        LUNCH_DINNER_TIME: 'Lunch / dinner time',
        YES: 'Yes',
        NO: 'No',
        CUSTOM: 'Custom message'
    },
    LANGUAGES: {
        [LanguageEnum.ENGLISH]: 'English',
        [LanguageEnum.DUTCH]: 'Dutch',
        [LanguageEnum.FRENCH]: 'French'
    },
    COMBINE_TELEMETRY: {
        combineName: 'Combine name',
        eventTime: 'Event time',
        vehicleId: 'Vehicle ID',
        relativeGraintankContent: 'Relative Graintank content in %',
        grainVolumeInGraintank__m3: 'Grain volume in the Graintank in m³',
        remainingTimeToFullGraintank__s: 'Remaining time to 100% full graintank',
        grower: 'Grower',
        farm: 'Farm',
        field: 'Field',
        cropType: 'Crop',
        drumrotor_speed_newholland__rpm: 'Drum speed',
        concavePosition: 'Drum Concave pos.',
        rotSepConcave: 'Rot. sep. concave',
        cleanFanSpd: 'Clean Fan Speed',
        upSievePosition: 'Upper Sieve',
        loSievePosition: 'Lower Sieve',
        preSievePosition: 'Pre Sieve',
        chopper_speed__rpm: 'Chopped speed',
        engFuelRate: 'Engine Fuel Rate',
        groundspeed__km_h: 'Ground speed',
        grainBinLevel: 'Grain Bin Level',
        engineLoad: 'Engine load',
        fuelLevel: 'Fueltank Content',
        instantaneousCropFlow: 'Grain Bin Capacity',
        actualYield: 'Yield',
        cropMoisture: 'Moisture',
        grainTemperature: 'Grain Temperature',
        brokenGrainSummary: 'Broken grain / sens',
        mogSummary: 'MOG / sens',
        returnSummary: 'Returns / sens',
        sieveGrainLossSummary: 'Sieve loss / sens',
        separatorLossSummary: 'Separator Loss / sens',
        dryMatterpc: 'Dry / Fresh DryMatter',
        crudeProteinpc: 'Dry/Fresh CrudeProtein',
        starchpc: 'Dry/Fresh Starch',
        ADFpc: 'Dry/Fresh ADF',
        NDFpc: 'Dry/Fresh NDF',
        ashpc: 'Dry/Fresh Ash',
        crudeFatpc: 'Dry/Fresh CrudeFat'
    },
    SPFH_TELEMETRY: {
        spfhName: 'SPFH name',
        // Farm data
        GrowerData: 'Grower',
        FarmData: 'Farm',
        FieldData: 'Field',
        Operator: 'Operator',

        // Crop data
        Crop: 'Crop',
        CropFlowDry: 'Crop Flow Dry',
        CropFlowWet: 'Crop Flow Wet',
        dryMatterpc: 'Dry / Fresh DryMatter',
        crudeProteinpc: 'Dry/Fresh CrudeProtein',
        starchpc: 'Dry/Fresh Starch',
        ADFpc: 'Dry/Fresh ADF',
        NDFpc: 'Dry/Fresh NDF',
        ashpc: 'Dry/Fresh Ash',
        crudeFatpc: 'Dry/Fresh CrudeFat',

        // Machine data
        Machine: 'Machine',
        ProductIdModel: 'Product ID Model',
        ProductIdBrand: 'Product ID Brand',
        GroundSpeed: 'Ground Speed',
        Header_type: 'Header Type',
        WorkCondition: 'Work condition',
        Active_working_width: 'Active working width',
        EngineRpm: 'Engine RPM',
        Number_of_knives: 'Number of knives',
        Loc: 'Length of cut (LOC)',
        MetalDetected: 'Metal Detected',
        MetalPosition: 'Metal Position',
        SpoutRotPosition: 'Spout Rotation Position',
        CpRollsOpening: 'Cp Rolls Opening',
        cpRollSpeedRatio: 'Cp Roll Speed Ratio',
        FourWheelDrive: 'Four Wheel Drive',
        SpoutDeflectorPosition: 'Spout Deflector Position',
        EngineLoad: 'Engine Load',
        EngFuelRate: 'Engine Fuel Rate',
        EngCoolantTemp: 'Engine Coolant Temp',
        FuelLevel: 'Fuel Level',
        UreaTankLevel: 'Urea Tank Level',
        GreasingObstruction: 'Greasing Obstruction',
        SilageAdditivesTankLevel: 'Silage Additives Tank Level',
        SilageAdditivesFlow: 'Silage Additives Flow',
        HarvestSpeed: 'Harvest Speed',
        TargetId: 'Unload Target',
        HarvestedVolumeToTarget: 'Unloaded',
        Additive_setpoint_dosis: 'Additive Setpoint Dosis'
    },
    BALER_TELEMETRY: {
        balerName: 'Baler name',
        eventTime: 'Event time',
        grower: 'Grower',
        farm: 'Farm',
        field: 'Field',
        operator: 'Operator',
        setPointLength: 'Length',
        density: 'Density',
        cropCutter: 'Crop Cutter',
        engaged: 'Engaged',
        notEngaged: 'Not engaged',
        plungerRatio: 'Plunger ratio'
    },
    BALER_LOAD_REPORT: {
        TITLE: 'Baler load report',
        bale: 'Bale',
        balerName: 'Baler name',
        creation: 'Creation',
        weight: 'Weight',
        length: 'Length',
        moisture: 'Moisture',
        capacity: 'Capacity',
        density: 'Density',
        plungerRatio: 'Plunger ratio',
        nrOfFlakes: 'Nr of flakes',
        cropCutter: 'Crop cutter',
        engaged: 'Engaged',
        notEngaged: 'Not engaged'
    },
    FIELD_REPORT: {
        TITLE: 'Field report',
        DOWNLOAD_PDF: 'PDF download',
        DOWNLOAD_CSV: 'CSV download',
        loading: 'Retrieving field report...',
        teamName: 'Team',
        time: 'Time',
        year: 'Year',
        startTime: 'Start',
        endTime: 'End',
        growerData: 'Grower',
        farmData: 'Farm',
        fieldData: 'Field',
        operator: 'Operator',
        load__m3: 'Total Load',
        actlYield__kg_ha: 'Avg Yield',
        current_Crop: 'Crop',
        avgLoc__mm: 'Avg LOC',
        cropDry__ton: 'Total Crop Dry',
        cropWet__ton: 'Total Crop Wet',
        combines: 'Combines',
        spfhs: 'SPFHs',
        machine: 'Machine',
        productIdBrand: 'Brand',
        headerType: 'Header type',
        workWidthLength__m: 'Work width (in m)',
        workWidthNrOfRows: 'Work width (nr of rows)',
        avgEngineLoad: 'Avg Engine Load',
        avgFuelRate__l_h: 'Avg Fuel Rate',
        totalFuelConsumption: 'Total Fuel Consumption',
        avgCpRollOpening__mm: 'Avg CP Roll Opening',
        avgCpRollSpeedRatio: 'Avg CP Roll Speed Ratio',
        diffSilageAdditivesTankLevel: 'Diff Silage Add Level',
        totalSilageAdditivesConsumption__l: 'Total Silage Add Cons',
        sharpenings: 'Sharpening procedures',
        firstMetalDetected: 'First time metal detected',
        firstGreasingObstruction: 'Greasing obstruction',
        bins: 'Service vehicles',
        teamMembers: 'Team members',
        currentCrop: 'Crop',
        fuelConsumption: 'Fuel consumption',
        cropMoist: 'Avg Crop Moisture',
        grainTemp: 'Avg Grain Temp',
        brokenGrain: 'Avg Broken grain',
        mog: 'Avg MOG',
        returns: 'Avg Returns',
        sieveLoss: 'Avg Sieve Loss',
        separatorLoss: 'Avg Separation Loss',
        dry_matter: 'Avg Dry/Fresh DryMatter',
        starch: 'Avg Dry/Fresh Starch',
        crude_protein: 'Avg Dry/Fresh CrudeProtein',
        acid_detergeant_fiber: 'Avg Dry/Fresh ADF',
        neutral_detergean_fiber: 'Avg Dry/Fresh NDF',
        ash: 'Avg Dry/Fresh Ash',
        crude_fat: 'Avg Dry/Fresh CrudeFat',
        totalNumberOfBales: 'Total nr of Bales',
        avgLengthOfBales__m: 'Avg Bale Length',
        avgMoisture: 'Avg Bale Moisture',
        avgWeight__kg: 'Avg Bale Weight',
        totalWeight__kg: 'Total Bale Weight',
        balerCapacity__bale_hr: 'Baler capacity',
        avgPlungerRatio: 'Avg Plunger Ratio',
        operational_data: 'Operational data:',
        crop_data: 'Crop data:',
        machine_data: 'Machine data:',
        firstShearboltFlywheelAlarm: 'Shrbolt Flywheel Alarm',
        firstShearboltNeedleYokeAlarm: 'Shrbolt Needle Yoke Al',
        firstShearboltStufferAlarm: 'Shrbolt Stuffer Alarm',
        firstMajorPickupSlipAlarm: 'Major Pickup Slip Al',
        engine_load: 'Engine load',
        drumrotor_speed_newholland__rpm: 'Drum speed',
        concave_position__mm: 'Drum Concave pos.',
        rotor_separation_concave: 'Rot. sep. concave',
        clean_fan_speed__rpm: 'Clean Fan Speed',
        upper_sieve_position__mm: 'Upper Sieve',
        lower_sieve_position__mm: 'Lower Sieve',
        presieve_position__mm: 'Pre Sieve'
    },
    BOUNDARIES: {
        TITLE: 'Boundaries',
        SUBTITLE:
            'Boundaries are the contours of your fields. Fields with boundaries enable more (realtime) statistics (e.g. time until finished)',
        SEARCH: {
            PLACEHOLDER: 'Search e.g. wheat, baudoin, ...'
        },
        TYPES: {
            EVO_PORTAL: 'EVO portal',
            HISTORICAL: 'Historic field coverage',
            MANUAL: 'Created manually',
            TEAM: 'Created in team'
        },
        TABLE: {
            HEADERS: {
                NAME: 'Name',
                CROP: 'Crop',
                CREATED: 'Created',
                FIELD_SIZE: 'Acreage',
                TYPE: 'Source'
            }
        },
        FEATURES: {
            DIGIFARM: 'Use Digifarm',
            SENTINEL: 'Use Sentinel',
            SYNC_DATA_ON_CREATE: 'Import historical data'
        },
        CREATE: 'Create new boundary',
        ACREAGE: 'Acreage',
        SAVE: 'Save boundary',
        FORM: {
            NAME_LABEL: 'Name',
            NAME_ERROR_REQUIRED: 'Name must be filled in',
            NAME_ERROR_LENGTH: 'Name cannot be longer than 50 characters',
            CONFIRM_BUTTON: 'Add'
        }
    },
    EVO_PORTAL: {
        UNAVAILABLE: 'Only available when logged in with EVO portal account',
        IMPORT: 'Import from EVO portal',
        EXPORT: 'Export to EVO portal'
    },
    POLYGON_MAP: {
        START_DRAWING: 'Start drawing',
        REMOVE_DRAWING: 'Remove drawing',
        STOP_DRAWING: 'Stop drawing',
        SELECT_BOUNDARY: 'Zoom in and select a boundary.',
        DRAW_TEXT: 'If your boundary is not available, you can',
        DRAW_ACTION: 'draw'
    },
    MAP: {
        TEAM_STOPPED: 'Team stopped',
        VIEWING_AS_GROWER: 'Viewing only'
    },
    ADMIN: {
        VEHICLE: 'Vehicle',
        API_MANAGEMENT: 'API management',
        MAC_ADDRESS: 'Mac address',
        LOGS: 'Logs',
        START_UPLOADING_LOGS: 'Start new upload',
        UPLOAD_STATUS: {
            NEVER_REQUESTED: 'No logs have been uploaded yet',
            IN_PROGRESS: 'Attempting to upload logs',
            SUCCEEDED: 'Logs have been uploaded successfully',
            FAILED: (failureMessage: string) => `Failed to upload logs: ${failureMessage}`
        },
        LINK: 'link',
        FORBIDDEN: 'You are not an admin',
        LINK_TO_STORAGE: 'Go to storage account',
        THIRD_PARTY: {
            TITLE: 'Third party data consumers'
        }
    },
    TEAM_PICKER: {
        SORT: {
            BY_TIME: 'Sort by time',
            ALPHABETICALLY: 'Sort alphabetically'
        },
        ERROR: {
            NO_TEAM: 'No teams',
            CREATE_TEAM: ' - please create a team using the mobile application',
        },
    },
    APDT_ADVICE: {
        TITLE: 'Advice',
        NEW_ADVICE: 'New advice',
        SHOW_DATA: 'Show sources',
        NO_PREV_ADVICES: 'No previous advices generated',
        PREV_ADVICES: 'Previous advices',
        PREV_ADVICES_TABLE: {
            ACTION: 'Action',
            CROP: 'Crop',
            CREATED: 'Created'
        },
        ACTIONS: {
            SEEDBED_PREPARATION: 'Seedbed prepation',
            LIMING: 'Liming',
            SOWING: 'Sowing',
            FUSARIUM: 'Fusarium',
            APHID: 'Aphid',
            OULEMA: 'Oulema',
            FERTILIZING_N1: 'Fertilizing N1',
            FERTILIZING_N2: 'Fertilizing N2',
            FERTILIZING_N3: 'Fertilizing N3',
            FERTILIZING: 'Fertilizing',
            CROP_PROTECTION: 'Crop protection',
            HARVESTING: 'Harvesting'
        },
        CROPS: {
            POTATOES: 'Potatoes',
            WINTER_WHEAT: 'Winter wheat'
        },
        DETAIL: {
            TITLE: 'Advice detail',
            MANUAL_INPUT: 'Manual input',
            SENSOR_DATA: 'Sensor data',
            MOTIVATION: 'Motivation',
        },
        SOIL_TYPES: {
            CLAY: 'Clay',
            SAND: 'Sand',
            SANDY_LOAM: 'Sandy Loam',
            LOAM: 'Loam'
        },
        CULTIVAR_TYPES: {
            STANDARD: 'Standard',
            HYBRID: 'Hybrid'
        },
        FORMS: {
            ACTIONS: {
                NEXT: 'Next',
                PREV: 'Previous',
                SUBMIT: 'Generate advice'
            },
            ACTION_LABEL: 'Select activity',
            SOURCE_HELP: 'Select all the sources you wish to reflect in the advice.',
            SOWING: {
                THOUSAND_KERNEL_WEIGHT: 'Thousand kernel weight',
                SOWING_TIME: 'Sowing time',
                CULTIVAR_TYPE: 'Cultivar type'
            },
            FUSARIUM: {
                PRECEDING_RISK: 'Preceding risk',
                VARIETY_RISK: 'Variety risk',
            },
            LIMING: {
                PH_LAB: 'PH Lab',
                SOIL_TYPE: 'Soiltype',
                LIMING_RECOMMENDATION: 'Liming recommendation',
                ZBW_LIME: 'Neutralising value'
            },
            FERTILIZING_N1: {
                ADVICE_N1: 'N1 recommendation',
                N_CONTENT_FERTILIZER: 'N value fertilizer',
                HOMOGENIZE: 'Homogenize',
            },
            FERTILIZING_N2: {
                ADVICE_N2: 'N2 recommendation',
                ADVICE_N3: 'N3 recommendation',
                N_CONTENT_FERTILIZER: 'N value fertilizer',
                HOMOGENIZE: 'Homogenize',
                ADD_N3: 'How much of the third fraction do you want to apply at this moment?'
            },
            FERTILIZING_N3: {
                ADVICE_N3: 'N3 recommendation',
                N_CONTENT_FERTILIZER: 'N value fertilizer',
                HOMOGENIZE: 'Homogenize',
            },
            SOURCE: {
                LABEL: 'Select source'
            },
        },
        HIDDEN_LAYERS: 'The following data could also be used to give an advice of higher precision.\n' +
            '            If you are interested, please contact your CNH dealer.',
        LAYERS: {
            SOURCES: {
                GEOPROSPECTOR_ECA: 'Geoprospector ECa',
                AUGMENTA_INDEX: 'Augmenta',
                SOIL_ANALYSIS: 'Soil Analysis',
                AGROPLANNING_YIELD: 'Agroplanning',
                AGDNA_CN1: 'AgDNA',
                WEATHERSTACK_WEATHER: 'Weather',
                WEATHERSTACK_FORECAST: 'forecast',
                DIGIFARM_IMAGERY: 'Deep Resolution Imagery',
                ACTIONS: {
                    MERGE: 'Merge'
                }
            },
            OPTIONS: {
                GEOPROSPECTOR_ECA_1: 'ECa 1',
                GEOPROSPECTOR_ECA_2: 'ECa 2',
                GEOPROSPECTOR_ECA_3: 'ECa 3',
                GEOPROSPECTOR_ECA_4: 'ECa 4',
                AUG_INDEX: 'Augmenta index',
                AGROPLANNING_YLD: 'Yield',
                AGROPLANNING_YLD_FLOW: 'Yield Flow',
                SOIL_ANALYSIS_PH: 'Ph',
                SOIL_ANALYSIS_TEMP: 'Temperature',
                SOIL_ANALYSIS_MOIS: 'Moisture',
                SOIL_ANALYSIS_EC: 'Electrical Conductivity',
                SOIL_ANALYSIS_N: 'Nitrogen',
                SOIL_ANALYSIS_PHOS: 'Phosphorus',
                SOIL_ANALYSIS_POT: 'Potassium',
                SOIL_ANALYSIS_SOIL: 'Soil',
                AGDNA_CN1_WIDTH: 'Width',
                AGDNA_CN1_PROTEIN: 'Protein',
                AGDNA_CN1_CROP_FLOW: 'Crop flow',
                AGDNA_CN1_MOISTURE: 'Moisture',
                WEATHERSTACK_WEATHER: 'Weatherstack weather',
                WEATHERSTACK_FORECAST: 'Weatherstack forecast',
                NDVI: 'NDVI',
                EVI: 'EVI',
                NDMI: 'NDMI',
                MI: 'MI',
                BSI: 'BSI',
            },
            STYLES: {
                ZONE_ONE: '1 zone',
                ZONE_TWO: '2 zones',
                ZONE_THREE: '3 zones',
                ZONE_FOUR: '4 zones',
                ZONE_FIVE: '5 zones'
            }
        },
        WEATHER_WIDGET: {
            LAST_WEEK: '-7d'
        },
        WEATHER_CODES: {
            DAY: {
                113: 'Clear/Sunny',
                116: 'Partly Cloudy',
                119: 'Cloudy',
                122: 'Overcast',
                143: 'Mist',
                176: 'Patchy rain nearby',
                179: 'Patchy snow nearby',
                182: 'Patchy sleet nearby',
                185: 'Patchy freezing drizzle nearby',
                200: 'Thundery outbreaks in nearby',
                227: 'Blowing snow',
                230: 'Blizzard',
                248: 'Fog',
                260: 'Freezing fog',
                263: 'Patchy light drizzle',
                266: 'Light drizzle',
                281: 'Freezing drizzle',
                284: 'Heavy freezing drizzle',
                293: 'Patchy light rain',
                296: 'Light rain',
                299: 'Moderate rain at times',
                302: 'Moderate rain',
                305: 'Heavy rain at times',
                308: 'Heavy rain',
                311: 'Light freezing rain',
                314: 'Moderate or Heavy freezing rain',
                317: 'Light sleet',
                320: 'Moderate or heavy sleet',
                323: 'Patchy light snow',
                326: 'Light snow',
                329: 'Patchy moderate snow',
                332: 'Moderate snow',
                335: 'Patchy heavy snow',
                338: 'Heavy snow',
                350: 'Ice pellets',
                353: 'Light rain shower',
                356: 'Moderate or heavy rain shower',
                359: 'Torrential rain shower',
                362: 'Light sleet showers',
                365: 'Moderate or heavy sleet showers',
                368: 'Light snow showers',
                371: 'Moderate or heavy snow showers',
                374: 'Light showers of ice pellets',
                377: 'Moderate or heavy showers of ice pellets',
                386: 'Patchy light rain in area with thunder',
                389: 'Moderate or heavy rain in area with thunder',
                392: 'Patchy light snow in area with thunder',
                395: 'Moderate or heavy snow in area with thunder',
            },
        },
    },
    AGDNA_CN1: {
        TITLE: 'AgDNA',
        SUBTITLE:
            'Import a cn1 file extracted from the machine or your AgDNA account to use for advice generation.',
        ADD_FILE: 'Add CN1 file',
        SEARCH: {
            PLACEHOLDER: 'Please select a file to upload.',
            NO_ZIP_FILE: 'Upload failed. Please try again with a cn1 zip file.',
        },
        STATUS: {
            PENDING: 'To be processed',
            PROCESSING: 'In progress',
            COMPLETE: 'Processed',
            FAILED: 'In error'
        },
        TABLE: {
            FILENAME: 'Filename',
            UPLOADED_ON: 'Uploaded on',
            STATUS: 'Status',
        }
    }
}

export default EN_TRANSLATIONS;
