<template>
  <div>
    <v-text-field :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N1.ADVICE_N1"
                  :rules="firstDoseRule"
                  v-model="value.adviceN1"
                  :disabled="disabled"></v-text-field>

    <v-text-field :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N1.N_CONTENT_FERTILIZER"
                  :rules="nitrogenContentRule"
                  v-model="value.n_ContentFertilizer"
                  :disabled="disabled"></v-text-field>

    <v-checkbox :label="translations.APDT_ADVICE.FORMS.FERTILIZING_N1.HOMOGENIZE"
                v-model="value.homogenize"
                :disabled="disabled"></v-checkbox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSoilTypes } from "@/apdtAdvice/Advice.service";
import { AdviceFertilizingN1Form, AdviceLimingForm } from "@/apdtAdvice/forms/forms.domain";
import {integerRule, maxRule, minRule, percentageRule} from "@/apdtAdvice/forms/InputRules";

export default {
  name: "AdviceFertilizingN1Input.form-item",
  components: {},
  props: {
    disabled: Boolean,
    value: {
      default: new AdviceFertilizingN1Form(),
      type: AdviceFertilizingN1Form
    }
  },
  data() {
    return {
      options: [],
      firstDoseRule: [
          integerRule(),
      ],
      nitrogenContentRule: percentageRule
    };
  },
  async mounted() {
    // getSoilTypes().then(value => this.options = value.map(item => ({value: item, label: this.translations.APDT_ADVICE.SOIL_TYPES[item]})));
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    })

  },
  methods: {},
  watch: {
    value: {
      handler: function(val, oldVal) {
        this.$emit("input", this.value);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
