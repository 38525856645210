<template>
  <div>
    <v-text-field :label="translations.APDT_ADVICE.FORMS.FUSARIUM.PRECEDING_RISK"
                  :rules="riskRule"
                  v-model="value.precedingRisk"
                  :disabled="disabled"></v-text-field>
    <v-text-field :label="translations.APDT_ADVICE.FORMS.FUSARIUM.VARIETY_RISK"
                  :rules="riskRule"
                  v-model="value.varietyRisk"
                  :disabled="disabled"></v-text-field>


  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSoilTypes } from "@/apdtAdvice/Advice.service";
import { AdviceFusariumForm, AdviceLimingForm, AdviceSowingForm } from "@/apdtAdvice/forms/forms.domain";
import { CultivarType } from "@/apdtAdvice/advice.enum";
import DateInput from "@components/DateInput.component.vue";
import moment from "moment";
import {integerRule, maxRule, minRule} from "@/apdtAdvice/forms/InputRules";

export default {
  name: "AdviceFusariumInput.form-item",
  components: {  },
  props: {
    disabled: Boolean,
    value: {
      default: new AdviceFusariumForm(),
      type: AdviceFusariumForm
    }
  },
  data() {
    return {
      options: [],
      riskRule: [integerRule(), minRule(1), maxRule(4)]
    };
  },
  async mounted() {
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    })

  },
  methods: {},
  watch: {
    value: {
      handler: function(val, oldVal) {
        this.$emit("input", this.value);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
