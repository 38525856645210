<template>
  <InnerLayout>
    <template v-slot:sideBarContent>
      <Title :title="translations.BOUNDARIES.TITLE"/>
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
      />
      <template v-else>
        <div class="farmBoundary__header">
          <div class="farmBoundary__backAndActions">
            <Back :to="{name: 'farmBoundaries'}"/>
            <div
                v-if="!farmBoundary.isNew()"
                class="farmBoundary__actions"
            >
              <v-tooltip top :disabled="hasEvoPortalAccess$">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
                    <UnderscriptIcon
                        :icon="icons.upload"
                        :underscript="translations.GENERAL.UPLOAD"
                        :color="hasEvoPortalAccess$ ? brandColors.PRIMARY : 'grey'"
                        :clickable="hasEvoPortalAccess$"
                        @click="exportFarmBoundary"
                    />
                  </div>
                </template>
                <span>{{ translations.EVO_PORTAL.UNAVAILABLE }}</span>
              </v-tooltip>
              <UnderscriptIcon
                  :icon="icons.delete"
                  :underscript="translations.GENERAL.DELETE"
                  color="red"
                  clickable
                  @click="removeFarmBoundary"
              />
            </div>
          </div>
        </div>
        <div class="farmBoundary__details">
          <v-form @submit.prevent="saveFarmBoundary" ref="farmBoundaryForm">
            <v-text-field
                :rules="formRules.name"
                :label="translations.BOUNDARIES.FORM.NAME_LABEL"
                required
                v-model="farmBoundary.name"
                autofocus
            />
          </v-form>
          <TextWithLabel :label="translations.BOUNDARIES.ACREAGE" :text="farmBoundary.fieldSize__ha.toString(2)"/>

          <GrowerPicker v-model="selectedGrower" @input="syncGrower" combobox-mode alphabetically :farm-teams="farmTeams"></GrowerPicker>
          <FarmPicker v-model="selectedFarmName" @input="syncFarmName" combobox-mode alphabetically :farm-teams="farmTeams" :grower="selectedGrower"></FarmPicker>
          <FieldPicker v-model="selectedFieldName" @input="syncFieldName" combobox-mode alphabetically :farm-teams="farmTeams" :grower="selectedGrower" :farm="selectedFarmName"></FieldPicker>
          <CropPicker v-model="selectedCropName" @input="syncCropName" combobox-mode alphabetically :farm-teams="farmTeams" :grower="selectedGrower" :farm="selectedFarmName" :field="selectedFieldName"></CropPicker>
          <v-checkbox :label="translations.BOUNDARIES.FEATURES.DIGIFARM" v-model="farmBoundary.digifarmEnabled"></v-checkbox>
          <v-checkbox :label="translations.BOUNDARIES.FEATURES.SENTINEL" v-model="farmBoundary.sentinelEnabled"></v-checkbox>
          <v-checkbox :label="translations.BOUNDARIES.FEATURES.SYNC_DATA_ON_CREATE" v-model="syncDataOnCreate" v-show="farmBoundary.isNew()"></v-checkbox>
        </div>
      </template>
    </template>
    <template v-slot:sideBarFooter>
      <div class="center">
        <v-btn
            color="primary"
            width="90%"
            :disabled="loading || farmBoundary.coordinates.length < 3"
            @click="saveFarmBoundary"
            :loading="submitting"
        >
          <v-icon small class="mr-1">{{ icons.edit }}</v-icon>
          {{ translations.BOUNDARIES.SAVE }}
        </v-btn>
      </div>
    </template>
    <template v-slot:content>
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
      />
      <PolygonMap
          v-else
          :initial-coordinates="farmBoundary.coordinates"
          @input="farmBoundary.coordinates = $event"
      />
    </template>
  </InnerLayout>
</template>

<script>
import InnerLayout from "@/layout/InnerLayout";
import Title from "@components/Title.component";
import { mapState } from "vuex";
import { icons } from "@utils/icons.utils";
import Back from "@components/Back.component";
import UnderscriptIcon from "@components/UnderscriptIcon.component";
import { BrandingService, FarmBoundary } from "shared-frontend";
import FarmBoundaryService from '@services/farmBoundary.service';
import TextWithLabel from "@components/TextWithLabel.component";
import PolygonMap from "@/map/PolygonMap.component";
import ModalService from "@/modals/modal.service";
import { ModalTypeEnum } from "@/modals/modalType.enum";
import EvoPortalService from "@services/evoPortal.service";
import FarmTeamService from "@/farmTeam/farmTeam.service";
import GrowerPicker from "@components/picker/GrowerPicker.component.vue";
import FarmPicker from "@components/picker/FarmPicker.component.vue";
import FieldPicker from "@components/picker/FieldPicker.component.vue";
import CropPicker from "@components/picker/CropPicker.component.vue";
import { toAutocompleteItems } from "@components/picker/Picker.helper";

export default {
  components: {CropPicker, FieldPicker, FarmPicker, GrowerPicker, PolygonMap, TextWithLabel, UnderscriptIcon, Back, Title, InnerLayout},
  props: {
    farmBoundaryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      farmBoundary: null,
      growerSearch: null,
      selectedGrower: null,
      farmNameSearch: null,
      selectedFarmName: null,
      fieldNameSearch: null,
      selectedFieldName: null,
      cropNameSearch: null,
      selectedCropName: null,
      syncDataOnCreate: false,
      submitting: false,
      formRules: {
        name: [
          v => !!v || this.translations.BOUNDARIES.FORM.NAME_ERROR_REQUIRED,
          v => (v && v.length <= 50) || this.translations.BOUNDARIES.FORM.NAME_ERROR_LENGTH
        ]
      }
    }
  },
  async created() {
    const creating = this.farmBoundaryId === null;
    if (creating) {
      this.farmBoundary = FarmBoundary.createManual();
    } else {
      this.farmBoundary = await FarmBoundaryService.getFarmBoundary(this.farmBoundaryId)
      this.selectedGrower = toAutocompleteItems(this.translations.TEAM.GROWER, this.translations)(this.farmBoundary.growerName, null)
      this.selectedFarmName = toAutocompleteItems(this.translations.TEAM.FARM, this.translations)(this.farmBoundary.farmName, null)
      this.selectedFieldName = toAutocompleteItems(this.translations.TEAM.FIELD, this.translations)(this.farmBoundary.fieldName, null)
      this.selectedCropName = toAutocompleteItems(this.translations.TEAM.CROP, this.translations)(this.farmBoundary.cropName, null)
    }
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    }),
    loading() {
      return !this.farmBoundary;
    },
    icons() {
      return icons
    },
    brandColors() {
      return BrandingService.getColors(process.env.VUE_APP_BRAND);
    },
    farmTeams() {
      if (this.farmTeams$ === null) {
        return []
      } else {
        return this.farmTeams$
      }
    }
  },
  methods: {
    async saveFarmBoundary() {
      if (!this.isFormValid() || this.submitting) return;

      this.submitting = true
      if (this.farmBoundary.isNew()) {
        await FarmBoundaryService.createManualFarmBoundary(this.farmBoundary, this.syncDataOnCreate);
      } else {
        await FarmBoundaryService.updateFarmBoundary(this.farmBoundary);
      }
      this.submitting = false
      await this.$router.push({name: 'farmBoundaries'})
    },
    removeFarmBoundary() {
      ModalService.showModal(ModalTypeEnum.CONFIRM_DELETE_FARM_BOUNDARY, {
        farmBoundary: this.farmBoundary,
        onConfirm: async () => {
          await FarmBoundaryService.deleteFarmBoundary(this.farmBoundary)
          await this.$router.push({name: 'farmBoundaries'})
        }
      });
    },
    exportFarmBoundary() {
      ModalService.showModal(ModalTypeEnum.CONFIRM_UPLOAD_TO_EVO_PORTAL, {farmBoundaryId: this.farmBoundaryId})
    },
    isFormValid() {
      return this.$refs.farmBoundaryForm.validate();
    },
    syncGrower(input) {
      this.farmBoundary.growerName = input?.value
    },
    syncCropName(input) {
      this.farmBoundary.cropName = input?.value
    },
    syncFarmName(input) {
      this.farmBoundary.farmName = input?.value
    },
    syncFieldName(input) {
      this.farmBoundary.fieldName = input?.value
    }
  },
  subscriptions() {
    return {
      hasEvoPortalAccess$: EvoPortalService.hasEvoPortalAccess$,
      farmTeams$: FarmTeamService.farmTeams$,
    };
  },
}
</script>
