<template>
  <div>
    <v-text-field :label="translations.APDT_ADVICE.FORMS.SOWING.THOUSAND_KERNEL_WEIGHT"
                  :rules="thousandKernelWeightRule"
                  v-model="value.thousandKernelWeight"
                  :disabled="disabled"></v-text-field>

    <v-select
        v-model="value.cultivarType"
        :items="options"
        :label="translations.APDT_ADVICE.FORMS.SOWING.CULTIVAR_TYPE"
        item-text="label"
        item-value="value"
        :disabled="disabled"
        :rules="cultivarTypeRule"
    />

    <date-input :label="translations.APDT_ADVICE.FORMS.SOWING.SOWING_TIME" v-model="value.sowDate" :disabled="disabled" />

  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSoilTypes } from "@/apdtAdvice/Advice.service";
import { AdviceLimingForm, AdviceSowingForm } from "@/apdtAdvice/forms/forms.domain";
import { CultivarType } from "@/apdtAdvice/advice.enum";
import DateInput from "@components/DateInput.component.vue";
import moment from "moment";
import {integerRule, positiveNumberRule, requiredRule} from "@/apdtAdvice/forms/InputRules";

export default {
  name: "AdviceSowingInput.form-item",
  components: { DateInput },
  props: {
    disabled: Boolean,
    value: {
      default: new AdviceSowingForm(),
      type: AdviceSowingForm
    }
  },
  data() {
    return {
      options: [],
      cultivarTypeRule: [
        requiredRule()
      ],
      thousandKernelWeightRule: [
        integerRule(),
          positiveNumberRule
      ]
    };
  },
  async mounted() {
    this.options = Object.values(CultivarType).map(item => ({ value: item, label: this.translations.APDT_ADVICE.CULTIVAR_TYPES[item] }));
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    })

  },
  methods: {},
  watch: {
    value: {
      handler: function(val, oldVal) {
        this.$emit("input", this.value);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
