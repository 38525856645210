// INTERNAL
import {BaleServiceVehicleTypeEnum, BinTypeEnum, LanguageEnum} from 'shared-frontend';
import {Translations} from '@translations/translations.type';

const FR_TRANSLATIONS: Translations = {
    GENERAL: {
        CONFIRM: 'Confirmer',
        CANCEL: 'Annuler',
        BACK: 'Retour',
        UPLOAD: 'Télécharger',
        DELETE: 'Supprimer',
        EDIT: 'Modifier',
        STOP: 'Arrêter',
        OR: 'Ou',
        EXPORT: 'Exporter',
        CLOSE: 'Fermer',
        RETRY: 'Réessayer',
        COMING_SOON: 'Prochainement',
        REQUIRED: 'Champ obligatoire'
    },
    MENU: {
        SETTINGS: 'Paramètres',
        LOG_OUT: 'Déconnexion',
        BACK_TO_MY_FARM: 'Retour à ma ferme',
        TO_ADMIN_PAGE: 'Vers l\'administration',
        TO_PORTAL: 'Retour au portail'
    },
    HEADER_TICKER: {
        TEAM_IDENTIFIER_LABEL: 'Équipe'
    },
    MESSAGING: {
        TITLE: 'Messagerie',
        SEND_MESSAGE: 'Envoyer un message',
        SEARCH: 'Rechercher des messages',
        TABLE: {
            HEADERS: {
                CREATED_ON: 'Envoyé le',
                MESSAGE: 'Message',
                CREATED_BY: 'Expéditeur'
            }
        },
        FORM: {
            MESSAGE_LABEL: 'Saisir le message',
            MESSAGE_ERROR_LENGTH: 'Maximum 250 caractères'
        },
        UNKNOWN_USER: 'Inconnu',
        DISABLED: 'Vous ne pouvez pas envoyer de messages dans les équipes arrêtées'
    },
    REPORTS: {
        TITLE: 'Rapports',
        NO_REPORTS:
            "Aucun rapport disponible pour le moment. Le rapport de terrain devient disponible lorsque vous arrêtez l'équipe. Dès que vous ajoutez des véhicules de service à l'équipe, vous pourrez également télécharger des rapports de bennes.",
        YEAR_REPORT: {
            TITLE: 'Rapport annuel'
        },
        FIELD_REPORT: {
            TITLE: 'Rapport de terrain'
        },
        BIN_REPORTS: {
            TITLE: 'Rapports de bennes'
        }
    },
    TABS: {
        OVERVIEW: 'Vue d\'ensemble',
        WORKFORCE: 'Main-d\'œuvre et véhicules',
        ABOUT: 'À propos'
    },
    ERRORS: {
        GENERAL_TITLE: 'Erreur',
        GENERAL: "Désolé, quelque chose s'est mal passé. Veuillez réessayer plus tard..."
    },
    GEOLOCATION_FAILED: {
        TITLE: "Échec de récupération de la localisation du navigateur !",
        MESSAGE: "Désolé ! Un problème est survenu lors de la récupération de votre localisation par le navigateur."
    },
    TEAM: {
        TEAM_NAME: 'Nom de l\'équipe',
        GROWER: 'Agriculteur',
        FARM: 'Ferme',
        FIELD: 'Champ',
        YEAR: 'Année',
        CROP: 'Culture',
        OPERATOR: 'Opérateur',
        FARM_MANAGER: 'Gérant de ferme',
        UNKNOWN: 'Inconnu'
    },
    TEAM_INFO: {
        TITLE: 'Informations sur l\'équipe'
    },
    NO_TEAM: 'Aucune équipe sélectionnée',
    NO_TEAMS: 'Aucune équipe disponible',
    OVERVIEW: {
        TEAM_NAME_LABEL: 'Équipe : ',
        LABELS: {
            YEAR_UNKNOWN: 'Année inconnue',
            GROWER_UNKNOWN: 'Agriculteur inconnu',
            FARM_UNKNOWN: 'Ferme inconnue',
            FIELD_UNKNOWN: 'Champ inconnu',
            CROP_UNKNOWN: 'Culture inconnue',
            TEAM_UNKNOWN: 'Équipe inconnue'
        },
        STOP_TEAM: 'Arrêter l\'équipe',
        NO_TEAMS: {
            TITLE: 'Vous n\'avez aucune équipe sur le terrain !',
            PARAGRAPH_1:
                'Vous pouvez créer une équipe en utilisant l\'application mobile (Android ou iOS), en vous connectant avec le même e-mail et mot de passe, et en créant une nouvelle équipe.',
            PARAGRAPH_2:
                'Vous pouvez créer une équipe en utilisant l\'application mobile (Android ou iOS), en vous connectant avec le même e-mail et mot de passe, et en créant une nouvelle équipe.'
        },
        STOPPED: 'arrêté'
    },
    ABOUT: {
        PARAGRAPH_1: "L'application Harvest Coordination est une application de premier ordre qui valorise vos données télématiques.",
        PARAGRAPH_2:
            "En premier lieu, elle aide à améliorer la coordination entre vos employés et vos machines sur le terrain et sur la route. En second lieu, elle vous fournit un outil de géo-traceabilité unique de vos cultures et des paramètres de nutrition des cultures.",
        PARAGRAPH_3:
            "L'application est disponible en version GRATUITE, mais pour utiliser l'ensemble des fonctionnalités, vous avez besoin du dispositif de coordination des récoltes (numéro de pièce 12376543) qui doit être installé sur vos moissonneuses-batteuses. Avec le dispositif, vous profiterez des avantages de l'utilisation efficace de vos données volumineuses.",
        PARAGRAPH_4:
            "Important ! Pour créer une barrière, vous devez cliquer avec votre curseur sur la carte dans l'onglet 'Vue d'ensemble' et appuyer pendant 3 secondes de manière intermittente. Pour supprimer cette barrière, il suffit de cliquer à nouveau sur l'icône de la barrière sur la carte."
    },
    DASHBOARD: {
        ACTIVE_TEAMS: 'Équipes actives',
        WORKFORCE_USERS: 'Effectif',
        WORKFORCE_USERS_INFO:
            'Votre effectif comprend toutes les personnes capables de travailler pour vous. Ces personnes peuvent rejoindre n\'importe laquelle de vos équipes dans l\'application mobile (et devenir membre d\'une équipe sur le terrain). Si vous ajoutez une personne, elle recevra un e-mail l\'informant de cet événement.',
        INACTIVE: 'inactif',
        WORKFORCE_SERVICE_VEHICLES: 'Mes véhicules de service',
        WORKFORCE_SERVICE_VEHICLES_INFO:
            'Les véhicules de service que vous possédez (en tant que gérant de ferme) ou que vous utilisez fréquemment. Tout membre de l\'équipe sur le terrain peut ajouter l\'un de ces véhicules de service à une équipe sur le terrain active.',
        BIN_CAPACITY_UNIT: 'm³',
        WORKFORCE_ARABLE_VEHICLES: 'Mes véhicules arables',
        WORKFORCE_ARABLE_VEHICLES_INFO:
            'Les véhicules arables (moissonneuse-batteuse, SPFH, presse à balles) que vous possédez (en tant que gérant de ferme) ou que vous utilisez fréquemment. Tout membre de l\'équipe sur le terrain peut ajouter l\'un de ces véhicules à une équipe sur le terrain active.',
        WORKFORCE_GROWERS: 'Agriculteurs',
        WORKFORCE_GROWERS_INFO:
            'Les agriculteurs des champs que vous avez récoltés. Vous pouvez les inviter via leur adresse e-mail et ils pourront alors voir l\'activité et les rapports concernant leurs champs uniquement.',
        ADD: 'Ajouter',
        NO_WORKFORCE: {
            PARAGRAPH_1: "Il n'y a aucune personne dans votre effectif",
            PARAGRAPH_2:
                'Veuillez ajouter votre premier membre de l\'effectif afin qu\'il/elle puisse rejoindre l\'une de vos équipes dans l\'application mobile.'
        },
        NO_FARM_BINS: {
            PARAGRAPH_1: 'Vous n\'avez aucun véhicule de service enregistré.',
            PARAGRAPH_2: 'Veuillez ajouter votre premier véhicule de service pour pouvoir le joindre à une équipe sur le terrain plus tard.'
        },
        NO_FARM_ARABLE_VEHICLES: {
            PARAGRAPH_1: 'Vous n\'avez aucun véhicule arable enregistré.',
            PARAGRAPH_2: 'Veuillez créer une équipe dans l\'application mobile et connecter votre premier véhicule arable.'
        },
        NO_GROWERS: {
            PARAGRAPH_1: 'Vous n\'avez aucune équipe avec un "agriculteur" spécifié !',
            PARAGRAPH_2:
                'Lorsque vous créez (ou modifiez une équipe existante) avec le champ "Agriculteur" spécifié, ces noms d\'agriculteurs deviendront disponibles afin que vous puissiez les inviter !'
        }
    },
    TEAM_MEMBERS: {
        COMBINES: 'Moissonneuses-batteuses',
        SPFHS: 'SPFHs',
        BALERS: 'Presses à balles',
        SERVICE_VEHICLES: 'Véhicules de service',
        USERS: 'Utilisateurs',
        NO_COMBINES_IN_TEAM: 'Aucune moissonneuse-batteuse dans l\'équipe',
        NO_BALERS_IN_TEAM: 'Aucune presse à balles dans l\'équipe',
        NO_BINS_IN_TEAM: 'Aucun véhicule de service dans l\'équipe',
        NO_USERS_IN_TEAM: 'Aucun utilisateur dans l\'équipe',
        REMOVED: 'retiré'
    },
    TEAM_PAGE: {
        FIELD_FINISHED_AT: 'Prévu pour finir le champ à :',
        TIME_UNTIL_BIN_FULL: 'Temps avant le remplissage de la benne',
        LAST_SEEN: 'vu pour la dernière fois',
        ESTIMATED_TOTAL_BALE_COUNT: 'Nombre de balles prévu :',
        ESTIMATED_BINS_LEFT: 'Bennes restantes nécessaires :',
        WEIGHT_TO_FINISH: 'Poids restant à récolter :',
        VOLUME_TO_FINISH: 'Volume restant à récolter :',
        VOLUME_WEIGHT_TO_FINISH: 'Volume/poids restant :'
    },
    CONTEXT_MENU: {
        DOWNLOAD_BIN_REPORT_PDF: 'Télécharger le rapport de la benne en PDF',
        DOWNLOAD_BIN_REPORT_CSV: 'Télécharger le rapport de la benne en CSV',
        HIDE_FIELD_COVERAGE: 'Masquer la couverture du champ',
        SHOW_FIELD_COVERAGE: 'Afficher la couverture du champ',
        SHOW_INFORMATION: 'Afficher les informations',
        SHOW_ESP_CAMS: 'Afficher la vue en temps réel'
    },
    TELEMETRY: {
        TITLE: 'Information instantanée',
        COLUMN_VALUE_TITLE: 'Variable',
        COLUMN_VARIABLE_TITLE: 'Valeur',
        loading: 'Récupération des données...',
        notAvailable: 'Désolé, aucune donnée disponible pour le moment !'
    },
    MODALS: {
        ADD_USER: {
            TITLE: 'Ajouter un membre de l\'équipe',
            FORM: {
                FIRST_NAME_LABEL: 'Prénom',
                FIRST_NAME_ERROR_REQUIRED: 'Le prénom doit être rempli',
                FIRST_NAME_ERROR_LENGTH: 'Le prénom ne peut pas dépasser 50 caractères',
                LAST_NAME_LABEL: 'Nom de famille',
                LAST_NAME_ERROR_REQUIRED: 'Le nom de famille doit être rempli',
                LAST_NAME_ERROR_LENGTH: 'Le nom de famille ne peut pas dépasser 50 caractères',
                EMAIL_LABEL: 'E-mail',
                EMAIL_ERROR_VALID: 'L\'e-mail doit être valide',
                PHONE_LABEL: 'Numéro de téléphone (ex. +32494...)',
                PHONE_ERROR_VALID: 'Le numéro de téléphone est invalide',
                CONFIRM_BUTTON: 'Ajouter'
            }
        },
        LINK_EDIT_GROWER: {
            TITLE_LINK: 'Lier le cultivateur',
            TITLE_EDIT: 'Modifier le cultivateur',
            FORM: {
                GROWER_NAME: 'Nom du cultivateur',
                FIRST_NAME_LABEL: 'Prénom',
                FIRST_NAME_ERROR_REQUIRED: 'Le prénom doit être rempli',
                FIRST_NAME_ERROR_LENGTH: 'Le prénom ne peut pas dépasser 50 caractères',
                LAST_NAME_LABEL: 'Nom de famille',
                LAST_NAME_ERROR_REQUIRED: 'Le nom de famille doit être rempli',
                LAST_NAME_ERROR_LENGTH: 'Le nom de famille ne peut pas dépasser 50 caractères',
                EMAIL_LABEL: 'E-mail',
                EMAIL_ERROR_VALID: 'L\'e-mail doit être valide',
                EMAIL_ERROR_SAME_AS_FARM_MANAGER:
                    'Vous ne pouvez pas vous inviter en tant que cultivateur ! Veuillez utiliser une autre adresse e-mail.',
                CONFIRM_BUTTON_LINK: 'Lier le cultivateur',
                CONFIRM_BUTTON_EDIT: 'Modifier le cultivateur'
            }
        },
        CONFIRM_UNLINK_GROWER: {
            TITLE: 'Dissocier le cultivateur',
            MESSAGE:
                "Êtes-vous sûr de vouloir dissocier ce cultivateur ? Il n'aura plus accès aux données de ses équipes dans le portail"
        },
        ADD_BIN: {
            TITLE: 'Ajouter un bac',
            FORM: {
                NAME_LABEL: 'Nom',
                NAME_ERROR_REQUIRED: 'Le nom doit être rempli',
                NAME_ERROR_LENGTH: 'Le nom ne peut pas dépasser 50 caractères',
                CAPACITY_LABEL: 'Capacité (en m³)',
                CAPACITY_ERROR_REQUIRED: 'La capacité doit être remplie.',
                CAPACITY_ERROR_POSITIVE: 'La capacité doit être un nombre positif',
                BIN_TYPE_LABEL: 'Type',
                BIN_TYPES: {
                    [BinTypeEnum.NORMAL_BIN]: 'Bac normal',
                    [BinTypeEnum.MOTHER_BIN]: 'Bac mère',
                    [BinTypeEnum.TRUCK]: 'Camion',
                    [BinTypeEnum.CHASER_BIN]: 'Bac de poursuite'
                },
                BIN_COLOR_LABEL: 'Couleur',
                CONFIRM_BUTTON: 'Ajouter'
            }
        },
        ADD_BALE_SERVICE_VEHICLE: {
            TITLE: 'Ajouter une emballeuse ou un véhicule de ramassage de balles',
            FORM: {
                NAME_LABEL: 'Nom',
                NAME_ERROR_REQUIRED: 'Le nom doit être rempli',
                NAME_ERROR_LENGTH: 'Le nom ne peut pas dépasser 50 caractères',
                BALE_SERVICE_VEHICLE_TYPE_LABEL: 'Type',
                BALE_SERVICE_VEHICLE_TYPES: {
                    // @ts-ignore
                    [BaleServiceVehicleTypeEnum.WRAPPER]: 'Emballeuse',
                    // @ts-ignore
                    [BaleServiceVehicleTypeEnum.PICKUP]: 'Ramassage'
                },
                BALE_SERVICE_VEHICLE_COLOR_LABEL: 'Couleur',
                CONFIRM_BUTTON: 'Ajouter'
            }
        },
        ADD_SERVICE_VEHICLE: {
            TITLE: 'Ajouter un véhicule de service',
            BIN: 'bac',
            BALE_SERVICE_VEHICLE: 'emballeuse ou ramassage de balles'
        },
        CONFIRM_DELETE_USER: {
            TITLE: 'Supprimer l\'utilisateur',
            MESSAGE: 'Êtes-vous sûr de vouloir supprimer cet utilisateur de votre main-d\'œuvre ?'
        },
        CONFIRM_DELETE_BIN: {
            TITLE: 'Supprimer le véhicule de service',
            MESSAGE: 'Êtes-vous sûr de vouloir supprimer ce véhicule de service de votre main-d\'œuvre ?'
        },
        CONFIRM_DELETE_BALE_SERVICE_VEHICLE: {
            TITLE: 'Supprimer le véhicule de service',
            MESSAGE: 'Êtes-vous sûr de vouloir supprimer ce véhicule de service de votre main-d\'œuvre ?'
        },
        CREATE_GATE: {
            TITLE: 'Créer une barrière',
            MESSAGE: 'Êtes-vous sûr de vouloir créer une barrière à cet emplacement ?'
        },
        DELETE_GATE: {
            TITLE: 'Supprimer la barrière',
            MESSAGE: 'Êtes-vous sûr de vouloir supprimer la barrière à cet emplacement ?'
        },
        COMING_SOON: {
            TITLE: 'Prochainement',
            MESSAGE: 'Cette fonctionnalité est actuellement en développement'
        },
        SETTINGS: {
            TITLE: 'Paramètres',
            FORM: {
                COMBINE_REPORT_TYPE_LABEL: 'Type de rapport (Moissonneuse)',
                SPFH_REPORT_TYPE_LABEL: 'Type de rapport (SPFH)',
                BALER_REPORT_TYPE_LABEL: 'Type de rapport (Presse à balles)',
                WARN_PROXIMITY_METERS_LABEL: 'Avertissement de proximité des véhicules (en m)',
                LANGUAGE_LABEL: 'Langue',
                LANGUAGE_ERROR_REQUIRED: 'La langue doit être renseignée',
                CONFIRM_BUTTON: 'Enregistrer les paramètres',
                BRANDING_LABEL: 'Marque',
                LOADING: 'Récupération des paramètres depuis le serveur',
                NOT_AVAILABLE:
                    "Impossible de récupérer les paramètres depuis le serveur. Veuillez vérifier votre connexion internet ou réessayer plus tard.",
                APDT_CREDENTIALS_LABEL: 'Identifiants APDT',
                CONSENT_LABEL: 'Consentement'
            }
        },
        EDIT_USER: {
            TITLE: 'Modifier membre de l\'équipe',
            FORM: {
                EMAIL_LABEL: 'Adresse e-mail',
                TELEPHONE_NUMBER_LABEL: 'Numéro de téléphone',
                ACTIVE_INACTIVE_LABEL: 'Actif',
                ACTIVE_INACTIVE_INFO_TEXT:
                    'Un utilisateur inactif ne peut pas utiliser l\'application mobile. Il ne peut rejoindre aucune de vos équipes de terrain.',
                AUTHORITY_LABEL: 'Autorité',
                AUTHORITY_INFO_TEXT:
                    "Un utilisateur avec 'autorité' peut voir des paramètres détaillés des machines et des cultures. Il/elle peut également retirer d'autres membres de l'équipe de terrain.",
                CONFIRM_BUTTON: 'Enregistrer'
            }
        },
        STOP_TEAM: {
            TITLE: (teamName: string) => `Arrêter l'équipe : ${teamName}`,
            MESSAGE:
                'Êtes-vous sûr de vouloir arrêter cette équipe ? Cette action est irréversible. Le rapport de terrain sera généré après l\'arrêt de l\'équipe.'
        },
        DELETE_TEAM: {
            TITLE: (teamName: string) => `Supprimer l'équipe : ${teamName}`,
            MESSAGE: 'Êtes-vous sûr de vouloir supprimer cette équipe ? Cette action est irréversible.'
        },
        COMBINE_FUEL_LOW: {
            TITLE: 'Niveau bas du réservoir de carburant de la moissonneuse !',
            MESSAGE: (fuelTankLevel: number, combineName: string) =>
                `Attention ! Le réservoir de carburant pour ${combineName} est à ${fuelTankLevel}%`
        },
        SPFH_FUEL_LOW: {
            TITLE: 'Niveau bas du réservoir de carburant du SPFH !',
            MESSAGE: (fuelTankLevel: number, spfhName: string) =>
                `Attention ! Le réservoir de carburant pour ${spfhName} est à ${fuelTankLevel}%`
        },
        BALER_FUEL_LOW: {
            TITLE: 'Niveau bas du réservoir de carburant de la presse à balles !',
            MESSAGE: (fuelTankLevel: number, balerName: string) =>
                `Attention ! Le réservoir de carburant pour ${balerName} est à ${fuelTankLevel}%`
        },
        CONFIRM_DELETE_FARM_BOUNDARY: {
            TITLE: 'Supprimer la frontière',
            MESSAGE: 'Êtes-vous sûr de vouloir supprimer cette frontière ?'
        },
        EDIT_TEAM_INFO: {
            TITLE: 'Modifier les infos de l\'équipe',
            CROP_ERROR_LENGTH: 'La culture ne peut pas dépasser 50 caractères',
            OPERATOR_ERROR_LENGTH: 'L\'opérateur ne peut pas dépasser 50 caractères',
            FARM_ERROR_LENGTH: 'La ferme ne peut pas dépasser 50 caractères',
            FIELD_ERROR_LENGTH: 'Le champ ne peut pas dépasser 50 caractères',
            GROWER_ERROR_LENGTH: 'Le cultivateur ne peut pas dépasser 50 caractères',
            NAME_ERROR_REQUIRED: 'Le nom doit être renseigné',
            YEAR_ERROR_REQUIRED: 'L\'année doit être renseignée',
            YEAR_ERROR_FUTURE: 'L\'année ne peut pas être dans le futur',
            YEAR_ERROR_MIN_YEAR: 'L\'année doit être au moins 2000'
        },
        CONFIRM_IMPORT_FROM_EVO_PORTAL: {
            TITLE: 'Importer depuis le portail EVO',
            MESSAGE:
                'Voulez-vous importer des limites depuis le portail EVO ? Cela écrasera les limites importées existantes.',
            LOADING_MESSAGE: 'L\'importation a été lancée. Vous pouvez fermer en toute sécurité cette fenêtre modale.'
        },
        CONFIRM_UPLOAD_TO_EVO_PORTAL: {
            TITLE: 'Télécharger sur le portail EVO',
            CONFIRM: (target: string) => `Voulez-vous télécharger ce ${target} sur le portail EVO ?`,
            EXPORTING: (target: string) => `Téléchargement de ${target}...`,
            FAILURE: (target: string) => `Échec du téléchargement de ${target}.`,
            SUCCESS: (target: string) =>
                `Le téléchargement a réussi. Pour utiliser le ${target}, vous devrez traiter le fichier avec le nom ci-dessous dans le portail EVO lui-même.`,
            SHOW_HOW_TO_PROCESS: 'Afficher comment traiter',
            HOW_TO_PROCESS: {
                STEP_1: 'Accédez au portail EVO',
                STEP_2: 'Cliquez sur l\'icône d\'engrenage.',
                STEP_3: 'Cliquez sur l\'onglet <strong>Fichiers de données</strong>.',
                STEP_4: (uploadedFileName: string) =>
                    `Cliquez sur le fichier portant le nom <strong>${uploadedFileName}</strong>.`,
                STEP_5: 'Cliquez sur <strong>Traiter</strong>.',
                STEP_6: 'Suivez les étapes dans l\'assistant.'
            }
        },
        MARKER: {
            TITLE: {
                PICTURE: 'Voir la photo',
                OBSTACLE: 'Voir l\'obstacle'
            },
            NAME: {
                LABEL: 'Nom'
            },
            LOCATION: {
                LABEL: 'Emplacement'
            },
            CREATED_ON: {
                LABEL: 'Créé le'
            },
            NO_PICTURE: 'Aucune photo n\'a été prise'
        },
        ESP_CAMS: {
            TITLE: 'Vue en temps réel',
            NO_ESP_CAMS: 'Aucune caméra active',
            FORM: {
                ESP_CAM: {
                    LABEL: 'Caméra',
                    ITEM(mac: string): string {
                        return `Caméra ${mac}`;
                    }
                }
            },
            ERROR_GET_ESP_CAMS: 'Impossible de récupérer les caméras'
        },
    },
    MESSAGES: {
        URGENT_UNLOAD_NEEDED: 'Déchargement urgent nécessaire',
        UNLOADING_IMMINENT: 'Déchargement imminent',
        DELAY: 'Je serai en retard dans le champ',
        LUNCH_DINNER_TIME: 'Pause déjeuner / dîner',
        YES: 'Oui',
        NO: 'Non',
        CUSTOM: 'Message personnalisé'
    },
    LANGUAGES: {
        [LanguageEnum.ENGLISH]: 'Anglais',
        [LanguageEnum.DUTCH]: 'Néerlandais',
        [LanguageEnum.FRENCH]: 'Français'
    },
    COMBINE_TELEMETRY: {
        combineName: 'Nom de la moissonneuse',
        eventTime: 'Heure de l\'événement',
        vehicleId: 'ID du véhicule',
        relativeGraintankContent: 'Contenu relatif de la trémie à grains en %',
        grainVolumeInGraintank__m3: 'Volume de grains dans la trémie à grains en m³',
        remainingTimeToFullGraintank__s: 'Temps restant pour remplir complètement la trémie à grains',
        grower: 'Agriculteur',
        farm: 'Ferme',
        field: 'Champ',
        cropType: 'Type de culture',
        drumrotor_speed_newholland__rpm: 'Vitesse du tambour',
        concavePosition: 'Position du concave du tambour',
        rotSepConcave: 'Concave de séparation rotative',
        cleanFanSpd: 'Vitesse du ventilateur de nettoyage',
        upSievePosition: 'Tamis supérieur',
        loSievePosition: 'Tamis inférieur',
        preSievePosition: 'Tamis préalable',
        chopper_speed__rpm: 'Vitesse du hachoir',
        engFuelRate: 'Consommation de carburant du moteur',
        groundspeed__km_h: 'Vitesse au sol',
        grainBinLevel: 'Niveau de la trémie à grains',
        engineLoad: 'Charge du moteur',
        fuelLevel: 'Contenu du réservoir de carburant',
        instantaneousCropFlow: 'Capacité de la trémie à grains',
        actualYield: 'Rendement réel',
        cropMoisture: 'Teneur en humidité de la culture',
        grainTemperature: 'Température des grains',
        brokenGrainSummary: 'Grains cassés / capteurs',
        mogSummary: 'MOG / capteurs',
        returnSummary: 'Retours / capteurs',
        sieveGrainLossSummary: 'Perte de grains au tamis / capteurs',
        separatorLossSummary: 'Perte au séparateur / capteurs',
        dryMatterpc: 'Matière sèche / Matière sèche fraîche',
        crudeProteinpc: 'Protéines brutes / Protéines brutes fraîches',
        starchpc: 'Amidon / Amidon frais',
        ADFpc: 'ADF / ADF frais',
        NDFpc: 'NDF / NDF frais',
        ashpc: 'Cendres / Cendres fraîches',
        crudeFatpc: 'Graisse brute / Graisse brute fraîche'
    },
    SPFH_TELEMETRY: {
        spfhName: 'Nom du hache-paille',
        // Farm data
        GrowerData: 'Agriculteur',
        FarmData: 'Ferme',
        FieldData: 'Champ',
        Operator: 'Opérateur',

        // Crop data
        Crop: 'Culture',
        CropFlowDry: 'Flux de culture sec',
        CropFlowWet: 'Flux de culture humide',
        dryMatterpc: 'Matière sèche / Matière sèche fraîche',
        crudeProteinpc: 'Protéines brutes / Protéines brutes fraîches',
        starchpc: 'Amidon / Amidon frais',
        ADFpc: 'ADF / ADF frais',
        NDFpc: 'NDF / NDF frais',
        ashpc: 'Cendres / Cendres fraîches',
        crudeFatpc: 'Graisse brute / Graisse brute fraîche',

        // Machine data
        Machine: 'Machine',
        ProductIdModel: 'Modèle d\'identifiant de produit',
        ProductIdBrand: 'Marque d\'identifiant de produit',
        GroundSpeed: 'Vitesse au sol',
        Header_type: 'Type de tête',
        WorkCondition: 'Condition de travail',
        Active_working_width: 'Largeur de travail active',
        EngineRpm: 'RPM du moteur',
        Number_of_knives: 'Nombre de couteaux',
        Loc: 'Longueur de coupe (LOC)',
        MetalDetected: 'Métal détecté',
        MetalPosition: 'Position du métal',
        SpoutRotPosition: 'Position de rotation du bec',
        CpRollsOpening: 'Ouverture des rouleaux Cp',
        cpRollSpeedRatio: 'Rapport de vitesse des rouleaux Cp',
        FourWheelDrive: 'Quatre roues motrices',
        SpoutDeflectorPosition: 'Position du déflecteur du bec',
        EngineLoad: 'Charge du moteur',
        EngFuelRate: 'Consommation de carburant du moteur',
        EngCoolantTemp: 'Température du liquide de refroidissement du moteur',
        FuelLevel: 'Niveau de carburant',
        UreaTankLevel: 'Niveau du réservoir d\'urée',
        GreasingObstruction: 'Obstruction de la lubrification',
        SilageAdditivesTankLevel: 'Niveau du réservoir d\'additifs pour l\'ensilage',
        SilageAdditivesFlow: 'Débit d\'additifs pour l\'ensilage',
        HarvestSpeed: 'Vitesse de récolte',
        TargetId: 'Cible de déchargement',
        HarvestedVolumeToTarget: 'Déchargé',
        Additive_setpoint_dosis: 'Dose de consigne d\'additif'
    },
    BALER_TELEMETRY: {
        balerName: 'Nom de la presse à balles',
        eventTime: 'Heure de l\'événement',
        grower: 'Agriculteur',
        farm: 'Ferme',
        field: 'Champ',
        operator: 'Opérateur',
        setPointLength: 'Longueur de consigne',
        density: 'Densité',
        cropCutter: 'Coupeuse de culture',
        engaged: 'Engagée',
        notEngaged: 'Non engagée',
        plungerRatio: 'Rapport du piston'
    },
    BALER_LOAD_REPORT: {
        TITLE: 'Rapport de charge de la presse à balles',
        bale: 'Balle',
        balerName: 'Nom de la presse à balles',
        creation: 'Création',
        weight: 'Poids',
        length: 'Longueur',
        moisture: 'Humidité',
        capacity: 'Capacité',
        density: 'Densité',
        plungerRatio: 'Rapport du piston',
        nrOfFlakes: 'Nombre de flocons',
        cropCutter: 'Coupeuse de culture',
        engaged: 'Engagée',
        notEngaged: 'Non engagée'
    },
    FIELD_REPORT: {
        TITLE: 'Rapport de champ',
        DOWNLOAD_PDF: 'Téléchargement PDF',
        DOWNLOAD_CSV: 'Téléchargement CSV',
        loading: 'Récupération du rapport de champ...',
        teamName: 'Équipe',
        time: 'Heure',
        year: 'Année',
        startTime: 'Début',
        endTime: 'Fin',
        growerData: 'Agriculteur',
        farmData: 'Ferme',
        fieldData: 'Champ',
        operator: 'Opérateur',
        load__m3: 'Charge totale',
        actlYield__kg_ha: 'Rendement moyen',
        current_Crop: 'Culture actuelle',
        avgLoc__mm: 'LOC moyen',
        cropDry__ton: 'Total de culture sèche',
        cropWet__ton: 'Total de culture humide',
        combines: 'Moissonneuses',
        spfhs: 'SPFHs',
        machine: 'Machine',
        productIdBrand: 'Marque',
        headerType: 'Type de tête',
        workWidthLength__m: 'Largeur de travail (en m)',
        workWidthNrOfRows: 'Largeur de travail (nombre de rangées)',
        avgEngineLoad: 'Charge moteur moyenne',
        avgFuelRate__l_h: 'Taux de consommation de carburant moyen',
        totalFuelConsumption: 'Consommation totale de carburant',
        avgCpRollOpening__mm: 'Ouverture moyenne de CP Roll',
        avgCpRollSpeedRatio: 'Rapport de vitesse moyen de CP Roll',
        diffSilageAdditivesTankLevel: 'Diff Niveau de réservoir d\'additifs pour l\'ensilage',
        totalSilageAdditivesConsumption__l: 'Consommation totale d\'additifs pour l\'ensilage',
        sharpenings: 'Procédures d\'affûtage',
        firstMetalDetected: 'Première détection de métal',
        firstGreasingObstruction: 'Obstruction de graissage',
        bins: 'Véhicules de service',
        teamMembers: 'Membres de l\'équipe',
        currentCrop: 'Culture actuelle',
        fuelConsumption: 'Consommation de carburant',
        cropMoist: 'Humidité moyenne de la culture',
        grainTemp: 'Température moyenne du grain',
        brokenGrain: 'Grain cassé moyen',
        mog: 'MOG moyen',
        returns: 'Retours moyens',
        sieveLoss: 'Perte au tamis moyenne',
        separatorLoss: 'Perte de séparation moyenne',
        dry_matter: 'Matière sèche moyenne/Fraîche',
        starch: 'Amidon moyen/Fraîche',
        crude_protein: 'Protéines brutes moyennes/Fraîches',
        acid_detergeant_fiber: 'Fibre détergente acide moyenne/Fraîche',
        neutral_detergean_fiber: 'Fibre détergente neutre moyenne/Fraîche',
        ash: 'Cendres moyennes/Fraîches',
        crude_fat: 'Graisse brute moyenne/Fraîche',
        totalNumberOfBales: 'Nombre total de balles',
        avgLengthOfBales__m: 'Longueur moyenne des balles',
        avgMoisture: 'Humidité moyenne des balles',
        avgWeight__kg: 'Poids moyen des balles',
        totalWeight__kg: 'Poids total des balles',
        balerCapacity__bale_hr: 'Capacité de la presse à balles',
        avgPlungerRatio: 'Rapport moyen du piston',
        operational_data: 'Données opérationnelles :',
        crop_data: 'Données sur la culture :',
        machine_data: 'Données sur la machine :',
        firstShearboltFlywheelAlarm: 'Premier avertissement de boulon de cisaillement du volant',
        firstShearboltNeedleYokeAlarm: 'Premier avertissement de boulon de cisaillement de l\'étrier d\'aiguille',
        firstShearboltStufferAlarm: 'Premier avertissement de boulon de cisaillement de la bourreuse',
        firstMajorPickupSlipAlarm: 'Premier avertissement de glissement majeur de la ramasseuse',
        engine_load: 'Charge moteur',
        drumrotor_speed_newholland__rpm: 'Vitesse du tambour',
        concave_position__mm: 'Position du concave',
        rotor_separation_concave: 'Concave de séparation du rotor',
        clean_fan_speed__rpm: 'Vitesse du ventilateur de nettoyage',
        upper_sieve_position__mm: 'Position du tamis supérieur',
        lower_sieve_position__mm: 'Position du tamis inférieur',
        presieve_position__mm: 'Position du pré-tamis'
    },
    BOUNDARIES: {
        TITLE: 'Limites',
        SUBTITLE:
            'Les limites sont les contours de vos champs. Les champs avec des limites permettent d\'obtenir davantage de statistiques (en temps réel, par exemple, le temps restant avant la fin).',
        SEARCH: {
            PLACEHOLDER: 'Recherchez par exemple blé, baudoin, ...'
        },
        TYPES: {
            EVO_PORTAL: 'Portail EVO',
            HISTORICAL: 'Couverture historique du champ',
            MANUAL: 'Créée manuellement',
            TEAM: 'Créée en équipe'
        },
        TABLE: {
            HEADERS: {
                NAME: 'Nom',
                CROP: 'Culture',
                CREATED: 'Créée',
                FIELD_SIZE: 'Superficie',
                TYPE: 'Source'
            }
        },
        FEATURES: {
            DIGIFARM: 'Utilisez Digifarm',
            SENTINEL: 'Utilisez Sentinel',
            SYNC_DATA_ON_CREATE: 'Importez données historiques'
        },
        CREATE: 'Créer une nouvelle limite',
        ACREAGE: 'Superficie',
        SAVE: 'Enregistrer la limite',
        FORM: {
            NAME_LABEL: 'Nom',
            NAME_ERROR_REQUIRED: 'Le nom doit être saisi',
            NAME_ERROR_LENGTH: 'Le nom ne peut pas dépasser 50 caractères',
            CONFIRM_BUTTON: 'Ajouter'
        }
    },
    EVO_PORTAL: {
        UNAVAILABLE: 'Disponible uniquement lorsque vous êtes connecté avec un compte du portail EVO',
        IMPORT: 'Importer depuis le portail EVO',
        EXPORT: 'Exporter vers le portail EVO'
    },
    POLYGON_MAP: {
        START_DRAWING: 'Commencer le dessin',
        REMOVE_DRAWING: 'Supprimer le dessin',
        STOP_DRAWING: 'Arrêter le dessin',
        SELECT_BOUNDARY: 'Zoomer et sélectionner une limite.',
        DRAW_TEXT: "Si votre limite n'est pas disponible, vous pouvez",
        DRAW_ACTION: 'dessiner'
    },
    MAP: {
        TEAM_STOPPED: 'Équipe arrêtée',
        VIEWING_AS_GROWER: 'Vue en tant que'
    },
    ADMIN: {
        VEHICLE: 'Véhicule',
        API_MANAGEMENT: 'Gestion de l\'API',
        MAC_ADDRESS: 'Adresse MAC',
        LOGS: 'Journaux',
        START_UPLOADING_LOGS: 'Commencer une nouvelle mise en ligne',
        UPLOAD_STATUS: {
            NEVER_REQUESTED: 'Aucun journal n\'a encore été mis en ligne',
            IN_PROGRESS: 'Tentative de mise en ligne des journaux',
            SUCCEEDED: 'Les journaux ont été mis en ligne avec succès',
            FAILED: (failureMessage: string) => `Échec de la mise en ligne des journaux : ${failureMessage}`
        },
        LINK: 'lien',
        FORBIDDEN: 'Vous n\'êtes pas administrateur',
        LINK_TO_STORAGE: 'Accéder au compte de stockage',
        THIRD_PARTY: {
            TITLE: 'Consommateurs de données tiers'
        }
    },
    TEAM_PICKER: {
        SORT: {
            BY_TIME: 'Trier par heure',
            ALPHABETICALLY: 'Trier par ordre alphabétique'
        },
        ERROR: {
            NO_TEAM: 'Aucune équipe',
            CREATE_TEAM: ' - veuillez créer une équipe à l\'aide de l\'application mobile',
        },
    },
    APDT_ADVICE: {
        TITLE: 'Conseil',
        NEW_ADVICE: 'Nouveau conseil',
        SHOW_DATA: 'Afficher les sources',
        NO_PREV_ADVICES: 'Aucun conseil précédent généré',
        PREV_ADVICES: 'Conseils précédents',
        PREV_ADVICES_TABLE: {
            ACTION: 'Action',
            CROP: 'Culture',
            CREATED: 'Créé'
        },
        ACTIONS: {
            SEEDBED_PREPARATION: 'Préparation du lit de semence',
            LIMING: 'Chaulage',
            SOWING: 'Semis',
            FUSARIUM: 'Fusarium',
            APHID: 'Aphid',
            OULEMA: 'Oulema',
            FERTILIZING_N1: 'Fertilisation N1',
            FERTILIZING_N2: 'Fertilisation N2',
            FERTILIZING_N3: 'Fertilisation N3',
            FERTILIZING: 'Fertilisation',
            CROP_PROTECTION: 'Protection des cultures',
            HARVESTING: 'Récolte'
        },
        CROPS: {
            POTATOES: 'Pommes de terre',
            WINTER_WHEAT: 'Blé d\'hiver'
        },
        DETAIL: {
            TITLE: 'Détails du conseil',
            MANUAL_INPUT: 'Saisie manuelle',
            SENSOR_DATA: 'Données du capteur',
            MOTIVATION: 'Motivation',
        },
        SOIL_TYPES: {
            CLAY: 'Argile',
            SAND: 'Sable',
            SANDY_LOAM: 'Limono-sableux',
            LOAM: 'Limon'
        },
        CULTIVAR_TYPES: {
            STANDARD: 'Standard',
            HYBRID: 'Hybride'
        },
        FORMS: {
            ACTIONS: {
                NEXT: 'Suivant',
                PREV: 'Précédent',
                SUBMIT: 'Générer le conseil'
            },
            ACTION_LABEL: 'Sélectionner l\'activité',
            SOURCE_HELP: 'Sélectionnez toutes les sources que vous souhaitez refléter dans le conseil.',
            SOWING: {
                THOUSAND_KERNEL_WEIGHT: 'Poids de mille grains',
                SOWING_TIME: 'Période de semis',
                CULTIVAR_TYPE: 'Type de cultivar'
            },
            FUSARIUM: {
                PRECEDING_RISK: 'Précédent de risque',
                VARIETY_RISK: 'Variété de risque',
            },
            LIMING: {
                PH_LAB: 'pH Lab',
                SOIL_TYPE: 'Type de sol',
                LIMING_RECOMMENDATION: 'Recommandation de chaulage',
                ZBW_LIME: 'Valeur neutralisante'
            },
            FERTILIZING_N1: {
                ADVICE_N1: 'Recommandation N1',
                N_CONTENT_FERTILIZER: 'Valeur N de l\'engrais',
                HOMOGENIZE: 'Homogénéiser',
            },
            FERTILIZING_N2: {
                ADVICE_N2: 'N2 Recommandation',
                ADVICE_N3: 'N3 Recommandation',
                N_CONTENT_FERTILIZER: 'Valeur N de l\'engrais',
                HOMOGENIZE: 'Homogénéiser',
                ADD_N3: 'Combien de 3 fraction vous voulez ajouter ?',
            },
            FERTILIZING_N3: {
                ADVICE_N3: 'N3 Recommandation',
                N_CONTENT_FERTILIZER: 'Valeur N de l\'engrais',
                HOMOGENIZE: 'Homogénéiser',
            },
            SOURCE: {
                LABEL: 'Sélectionner la source'
            },
        },
        HIDDEN_LAYERS: 'Les données suivantes pourraient également être utilisées pour fournir un conseil d\'une plus grande précision.\n' +
            'Si vous êtes intéressé, veuillez contacter votre concessionnaire CNH.',
        LAYERS: {
            SOURCES: {
                GEOPROSPECTOR_ECA: 'Geoprospector ECa',
                AUGMENTA_INDEX: 'Augmenta',
                SOIL_ANALYSIS: 'Analyse du sol',
                AGROPLANNING_YIELD: 'Agroplanning',
                AGDNA_CN1: 'AgDNA',
                WEATHERSTACK_WEATHER: 'Météo',
                WEATHERSTACK_FORECAST: 'Prévision météo',
                DIGIFARM_IMAGERY: 'Deep Resolution Imagery',
                ACTIONS: {
                    MERGE: 'Fusionner',
                }
            },
            OPTIONS: {
                GEOPROSPECTOR_ECA_1: 'ECa 1',
                GEOPROSPECTOR_ECA_2: 'ECa 2',
                GEOPROSPECTOR_ECA_3: 'ECa 3',
                GEOPROSPECTOR_ECA_4: 'ECa 4',
                AUG_INDEX: 'Index Augmenta',
                AGROPLANNING_YLD: 'Rendement',
                AGROPLANNING_YLD_FLOW: 'Flux de rendement',
                SOIL_ANALYSIS_PH: 'PH',
                SOIL_ANALYSIS_TEMP: 'Température',
                SOIL_ANALYSIS_MOIS: 'Humidité',
                SOIL_ANALYSIS_EC: 'Conductivité électrique',
                SOIL_ANALYSIS_N: 'Azote',
                SOIL_ANALYSIS_PHOS: 'Phosphore',
                SOIL_ANALYSIS_POT: 'Potassium',
                SOIL_ANALYSIS_SOIL: 'Sol',
                AGDNA_CN1_WIDTH: 'Largeur',
                AGDNA_CN1_PROTEIN: 'Protéine',
                AGDNA_CN1_CROP_FLOW: 'Flux de culture',
                AGDNA_CN1_MOISTURE: 'Humidité',
                WEATHERSTACK_WEATHER: 'Météo Weatherstack',
                WEATHERSTACK_FORECAST: 'Prévision météo Weatherstack',
                NDVI: 'NDVI',
                EVI: 'EVI',
                NDMI: 'NDMI',
                MI: 'MI',
                BSI: 'BSI',
            },
            STYLES: {
                ZONE_ONE: '1 zone',
                ZONE_TWO: '2 zones',
                ZONE_THREE: '3 zones',
                ZONE_FOUR: '4 zones',
                ZONE_FIVE: '5 zones'
            }
        },
        WEATHER_WIDGET: {
            LAST_WEEK: '-7d'
        },
        WEATHER_CODES: {
            DAY: {
                113: 'Ensoleillé',
                116: 'Partiellement nuageux',
                119: 'Nuageux',
                122: 'Couvert',
                143: 'Brume',
                176: 'Pluie éparse à proximité',
                179: 'Neige éparse à proximité',
                182: 'Grésil épars à proximité',
                185: 'Bruine verglaçante éparse à proximité',
                200: 'Foyers orageux à proximité',
                227: 'Rafales de neige',
                230: 'Blizzard',
                248: 'Brouillard',
                260: 'Brouillard givrant',
                263: 'Bruine légère éparse',
                266: 'Bruine légère',
                281: 'Bruine verglaçante',
                284: 'Forte bruine verglaçante',
                293: 'Pluie légère éparse',
                296: 'Pluie légère',
                299: 'Pluie modérée par moments',
                302: 'Pluie modérée',
                305: 'Pluie forte par moments',
                308: 'Pluie forte',
                311: 'Pluie verglaçante légère',
                314: 'Moderate or Heavy freezing rain',
                317: 'Light sleet',
                320: 'Moderate or heavy sleet',
                323: 'Patchy light snow',
                326: 'Light snow',
                329: 'Patchy moderate snow',
                332: 'Moderate snow',
                335: 'Patchy heavy snow',
                338: 'Heavy snow',
                350: 'Ice pellets',
                353: 'Light rain shower',
                356: 'Moderate or heavy rain shower',
                359: 'Torrential rain shower',
                362: 'Light sleet showers',
                365: 'Moderate or heavy sleet showers',
                368: 'Light snow showers',
                371: 'Moderate or heavy snow showers',
                374: 'Light showers of ice pellets',
                377: 'Moderate or heavy showers of ice pellets',
                386: 'Patchy light rain in area with thunder',
                389: 'Moderate or heavy rain in area with thunder',
                392: 'Patchy light snow in area with thunder',
                395: 'Moderate or heavy snow in area with thunder',
            },
        }
    },
    AGDNA_CN1: {
        TITLE: 'AgDNA',
        SUBTITLE:
            'Importez un fichier cn1 extrait de la machine ou de votre compte AgDNA pour l\'utiliser dans la génération de conseils.',
        ADD_FILE: 'Ajouter un fichier CN1',
        SEARCH: {
            PLACEHOLDER: 'Veuillez sélectionner un fichier à télécharger.',
            NO_ZIP_FILE: 'Échec du téléchargement. Veuillez réessayer avec un fichier cn1 zip.',
        },
        STATUS: {
            PENDING: 'En attente de traitement',
            PROCESSING: 'En cours de traitement',
            COMPLETE: 'Traité',
            FAILED: 'En erreur'
        },
        TABLE: {
            FILENAME: 'Nom de fichier',
            UPLOADED_ON: 'Téléchargé',
            STATUS: 'État',
        }
    }
}

export default FR_TRANSLATIONS;
