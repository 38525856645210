<template>
  <div>
    <v-select
        v-model="value.soilType"
        :items="options"
        :label="translations.APDT_ADVICE.FORMS.LIMING.SOIL_TYPE"
        item-text="label"
        item-value="value"
        :disabled="disabled"
        :rules="soilTypeRule"
    />

    <v-text-field :label="translations.APDT_ADVICE.FORMS.LIMING.PH_LAB"
                  :rules="phRule"
                  v-model="value.phLab"
                  :disabled="disabled"></v-text-field>

    <v-text-field :label="translations.APDT_ADVICE.FORMS.LIMING.LIMING_RECOMMENDATION"
                  :rules="preferredDoseRule"
                  v-model="value.limingRecommendation"
                  :disabled="disabled"></v-text-field>
    <v-text-field :label="translations.APDT_ADVICE.FORMS.LIMING.ZBW_LIME"
                  :rules="neutralizingValueRule"
                  v-model="value.zbwLime"
                  :disabled="disabled"></v-text-field>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSoilTypes } from "@/apdtAdvice/Advice.service";
import { AdviceLimingForm } from "@/apdtAdvice/forms/forms.domain";
import {floatingPointRule, integerRule, maxRule, minRule, requiredRule} from "@/apdtAdvice/forms/InputRules";

export default {
  name: "AdviceLimingInput.form-item",
  components: {},
  props: {
    disabled: Boolean,
    value: {
      default: new AdviceLimingForm(),
      type: AdviceLimingForm
    }
  },
  data() {
    return {
      options: [],
      soilTypeRule: [
          requiredRule()
      ],
      phRule: [
          floatingPointRule(),
          minRule(0),
          maxRule(14)
      ],
      preferredDoseRule: [
        integerRule(),
        minRule(0),
        maxRule(2000)
      ],
      neutralizingValueRule: [
          integerRule(),
          minRule(0),
          maxRule(100)
      ]
    };
  },
  async mounted() {
    getSoilTypes().then(value => this.options = value.map(item => ({ value: item, label: this.translations.APDT_ADVICE.SOIL_TYPES[item] })));
  },
  computed: {
    ...mapState({
      translations: (state) => state.translation.translations
    })

  },
  methods: {},
  watch: {
    value: {
      handler: function(val, oldVal) {
        this.$emit("input", this.value);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
