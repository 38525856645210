<template>
  <div>
    <div v-if="showDetail && infos.length" class="weather-container">
      <WeatherInfoComponent :header="true"></WeatherInfoComponent>
      <WeatherInfoComponent
        v-for="info in infos"
        :key="info.date"
        :info="info"
        :selectedDate="selectedDate"
      ></WeatherInfoComponent>

      <div style="padding: 8px 12px 8px 0">
        <div class="actions">
          <v-btn
            icon
            color="white"
            @click.stop="showDetail = false"
            title="Show detail"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>

          <v-progress-circular
              v-if="loadingInfo"
              color="white"
              :size="24"
              indeterminate
          />
        </div>
      </div>
    </div>

    <WeatherInfoTrigger
      v-if="currentWeather && !showDetail"
      :currentWeather="currentWeather"
      :loading="loadingInfo"
      @showDetail="showDetail = true"
      :historical-available="historicalAvailable"
    ></WeatherInfoTrigger>
  </div>
</template>

<script lang="ts">
import {CurrentWeather, WeatherInfo} from '@/weather/weather-info.domain';
import Vue, {PropType} from 'vue';
  import WeatherInfoComponent from '@/weather/WeatherInfo.vue';
  import WeatherInfoTrigger from '@/weather/WeatherInfoTrigger.vue';
  import { icons } from "@utils/icons.utils";
  import {weatherDataService} from "@services/weather-data.service";
import moment from "moment";

  export default Vue.extend({
    name: 'Weather',
    components: {
      WeatherInfoTrigger,
      WeatherInfoComponent
    },
    props: {
      farmBoundaryId: {
        type: String,
        default: null
      },
      selectedDay: {
        required: false,
        type: Object as PropType<moment.Moment | null>,
      }
    },
    created() {
      this.fetchWeatherForecast();
    },
    data() {
      const currentWeather: CurrentWeather | null = null;
      const infos: WeatherInfo[] = [];
      return {
        showDetail: false,
        currentWeather: currentWeather,
        infos: infos,
        loadingInfo: false
      }
    },
    computed: {
      icons() {
        return icons;
      },
      selectedDate(): string {
        return (this.selectedDay ?? moment()).format('YYYY-MM-DD');
      },
      historicalAvailable(): boolean {
        return this.infos != undefined && this.infos.length != 0
      }
    },
    methods: {
      fetchWeatherForecast() {
        this.loadingInfo = true;

        return weatherDataService.getWeatherForecast(this.farmBoundaryId, this.selectedDate)
            .then((infos: WeatherInfo[]) => {
              console.debug('infos', infos);
              this.infos = infos;
              if (moment(this.selectedDate).isSame(moment(), 'day')) {
                return weatherDataService.getCurrentWeather(this.farmBoundaryId);
              } else {
                return this.infos.find(i => i.date === this.selectedDate)?.toCurrentWeather()
              }
            })
            .then((currentWeather: CurrentWeather) => {
              console.debug('currentWeather', currentWeather);
              this.currentWeather = currentWeather;
              this.infos.find(i => i.date === this.selectedDate)?.setCurrentTemperature(currentWeather.currentT);
            })
            .finally(() => this.loadingInfo = false);
      }
    },
    watch: {
      selectedDate() {
        console.warn('selectedDate changed', this.selectedDate);
        this.fetchWeatherForecast();
      }
    }
  })
</script>

<style lang="scss" scoped>
@import './weather-colors';

.weather-container {
  background: $blue;
  color: white;
  border-radius: 8px;
  display: flex;
  cursor: default;
  .actions {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>
