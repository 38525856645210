// EXTERNAL
import {BehaviorSubject} from 'rxjs';
//
// INTERNAL
// Shared
import {API_ENDPOINTS, FarmBoundary, FarmBoundaryId, geoCoordinateToJSON, parseFarmBoundary} from 'shared-frontend';
//
// Domain
//
// Services
import httpService from '@services/http.service';
import {logger} from '@services/logger.service';

class FarmBoundaryService {
    private _farmBoundaries$: BehaviorSubject<FarmBoundary[]> = new BehaviorSubject<FarmBoundary[]>(null);

    public async init() {
        const farmBoundaries = await this.getFarmBoundaries();
    }

    public get farmBoundaries$() {
        return this._farmBoundaries$;
    }

    public async getFarmBoundaries(): Promise<FarmBoundary[]> {
        try {
            const response = await httpService.get(API_ENDPOINTS.FARM_BOUNDARIES());

            const farmBoundaries: FarmBoundary[] = response.data.map(json => parseFarmBoundary(json));
            this.setFarmBoundariesToState(farmBoundaries);

            return farmBoundaries;
        } catch (err) {
            logger.error('[FarmBoundaryService] - getFarmBoundaries', err);
        }
    }

    public async getFarmBoundary(farmBoundaryId: FarmBoundaryId): Promise<FarmBoundary> {
        try {
            const response = await httpService.get(API_ENDPOINTS.FARM_BOUNDARY(farmBoundaryId));

            const farmBoundary = parseFarmBoundary(response.data);

            logger.info('[FarmBoundaryService] - getFarmBoundary');

            return farmBoundary;
        } catch (err) {
            logger.error('[FarmBoundaryService] - getFarmBoundary', err);
        }
    }

    public async createManualFarmBoundary(farmBoundary: FarmBoundary, syncDataOnCreate: boolean): Promise<FarmBoundaryId> {
        const response = await httpService.post(API_ENDPOINTS.FARM_BOUNDARIES(), {
            name: farmBoundary.name,
            cropName: farmBoundary.cropName,
            growerName: farmBoundary.growerName,
            farmName: farmBoundary.farmName,
            fieldName: farmBoundary.fieldName,
            digifarmEnabled: farmBoundary.digifarmEnabled,
            sentinelEnabled: farmBoundary.sentinelEnabled,
            syncDataOnCreate: syncDataOnCreate,
            coordinates: farmBoundary.coordinates.map(coordinate => geoCoordinateToJSON(coordinate))
        });
        farmBoundary.farmBoundaryId = response.data;

        const newFarmBoundaries = [...this._farmBoundaries$.getValue(), farmBoundary];
        this.setFarmBoundariesToState(newFarmBoundaries)

        return farmBoundary.farmBoundaryId;
    }

    public async updateFarmBoundary(farmBoundary: FarmBoundary): Promise<FarmBoundaryId> {
        await httpService.put(API_ENDPOINTS.FARM_BOUNDARY(farmBoundary.farmBoundaryId), {
            name: farmBoundary.name,
            cropName: farmBoundary.cropName,
            growerName: farmBoundary.growerName,
            farmName: farmBoundary.farmName,
            fieldName: farmBoundary.fieldName,
            digifarmEnabled: farmBoundary.digifarmEnabled,
            sentinelEnabled: farmBoundary.sentinelEnabled,
            coordinates: farmBoundary.coordinates.map(coordinate => geoCoordinateToJSON(coordinate))
        });

        const updatedFarmBoundaries = this._farmBoundaries$.getValue().map(other => other.farmBoundaryId === farmBoundary.farmBoundaryId ? farmBoundary : other)
        this.setFarmBoundariesToState(updatedFarmBoundaries)

        return farmBoundary.farmBoundaryId;
    }

    public async deleteFarmBoundary(farmBoundary: FarmBoundary) {
        await httpService.delete(API_ENDPOINTS.FARM_BOUNDARY(farmBoundary.farmBoundaryId));

        const updatedFarmBoundaries = this._farmBoundaries$.getValue().filter(other => other.farmBoundaryId !== farmBoundary.farmBoundaryId)
        this.setFarmBoundariesToState(updatedFarmBoundaries)

        logger.info('[farmBoundary.service] - deleteFarmBoundary: farmBoundary DELETED');
    }

    public async importFromEvoPortal(): Promise<void> {
        await httpService.post(API_ENDPOINTS.FARM_BOUNDARY_IMPORT_FROM_EVO_PORTAL(), {});
        await this.getFarmBoundaries();
        logger.info('[farmBins.service] - importFromEvoPortal: farmBoundary imported');
    }

    public async uploadToEvoPortal(farmBoundaryId: FarmBoundaryId): Promise<string> {
        const response = await httpService.post(API_ENDPOINTS.FARM_BOUNDARY_UPLOAD_TO_EVO_PORTAL(farmBoundaryId), {});
        logger.info('[farmBins.service] - deleteFarmBin: farmBoundary exported');
        return response.data;
    }

    private setFarmBoundariesToState(farmBoundaries: FarmBoundary[]) {
        this._farmBoundaries$.next(farmBoundaries);
    }
}

export default new FarmBoundaryService();
