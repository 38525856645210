// INTERNAL
import {BaleServiceVehicleTypeEnum, BinTypeEnum, LanguageEnum} from 'shared-frontend';
import {Translations} from '@translations/translations.type';

const NL_TRANSLATIONS: Translations = {
    GENERAL: {
        CONFIRM: 'Bevestigen',
        CANCEL: 'Annuleren',
        BACK: 'Vorige',
        UPLOAD: 'Upload',
        DELETE: 'Verwijderen',
        EDIT: 'Bewerken',
        STOP: 'Stop',
        OR: 'Of',
        EXPORT: 'Export',
        CLOSE: 'Sluiten',
        RETRY: 'Herstarten',
        COMING_SOON: 'Binnenkort beschikbaar',
        REQUIRED: 'Verplicht veld'
    },
    MENU: {
        SETTINGS: 'Instellingen',
        LOG_OUT: 'Afmelden',
        BACK_TO_MY_FARM: 'Terug naar mijn Boerderij',
        TO_ADMIN_PAGE: 'Naar admin',
        TO_PORTAL: 'Terug naar portal'
    },
    HEADER_TICKER: {
        TEAM_IDENTIFIER_LABEL: 'Team'
    },
    MESSAGING: {
        TITLE: 'Berichten',
        SEND_MESSAGE: 'Stuur bericht',
        SEARCH: 'Zoek bericht',
        TABLE: {
            HEADERS: {
                CREATED_ON: 'Verstuurd op',
                MESSAGE: 'Bericht',
                CREATED_BY: 'Afzender'
            }
        },
        FORM: {
            MESSAGE_LABEL: 'Vul bericht in',
            MESSAGE_ERROR_LENGTH: 'Max 250 karakters'
        },
        UNKNOWN_USER: 'Onbekend',
        DISABLED: 'Je kunt geen berichten sturen in gestopte teams'
    },
    REPORTS: {
        TITLE: 'Rapporten',
        NO_REPORTS:
            'Er zijn momenteel geen rapporten beschikbaar. Het veldrapport wordt beschikbaar wanneer u het team stopt. Zodra u servicevoertuigen aan het team toevoegt, kunt u ook bakrapporten downloaden',
        YEAR_REPORT: {
            TITLE: 'Jaar rapport'
        },
        FIELD_REPORT: {
            TITLE: 'Veld rapport'
        },
        BIN_REPORTS: {
            TITLE: 'Bak rapport'
        }
    },
    TABS: {
        OVERVIEW: 'Overzicht',
        WORKFORCE: 'Personeelsbestand & voertuigen',
        ABOUT: 'Over',
    },
    ERRORS: {
        GENERAL_TITLE: 'Fout',
        GENERAL: 'Sorry, er is iets misgegaan. Probeer het later opnieuw...'
    },
    GEOLOCATION_FAILED: {
        TITLE: 'Ophalen van browserlocatie mislukt!',
        MESSAGE: 'Sorry! Er is een probleem opgetreden bij het ophalen van uw browserlocatie.'
    },
    TEAM: {
        TEAM_NAME: 'Team naam',
        GROWER: 'Kweker',
        FARM: 'Boerderij',
        FIELD: 'Veld',
        YEAR: 'Jaar',
        CROP: 'Gewas',
        OPERATOR: 'Operator',
        FARM_MANAGER: 'Bedrijfsleider',
        UNKNOWN: 'Onbekend'
    },
    TEAM_INFO: {
        TITLE: 'Team info'
    },
    NO_TEAM: 'Geen team geselecteerd',
    NO_TEAMS: 'Geen teams beschikbaar',
    OVERVIEW: {
        TEAM_NAME_LABEL: 'Team: ',
        LABELS: {
            YEAR_UNKNOWN: 'Jaar onbekend',
            GROWER_UNKNOWN: 'Kweker onbekend',
            FARM_UNKNOWN: 'Boerderij onbekend',
            FIELD_UNKNOWN: 'Veld ongekend',
            CROP_UNKNOWN: 'Gewas onbekend',
            TEAM_UNKNOWN: 'Team onbekend'
        },
        STOP_TEAM: 'Stop team',
        NO_TEAMS: {
            TITLE: 'Je hebt geen veld teams!',
            PARAGRAPH_1: 'Je kunt een team aanmaken door naar de mobiele app (Android of iOS) te gaan, in te loggen met hetzelfde e-mailadres en wachtwoord en een nieuw team aan te maken.',
            PARAGRAPH_2:
                'Je kunt een team aanmaken door naar de mobiele app (Android of iOS) te gaan, in te loggen met hetzelfde e-mailadres en wachtwoord en een nieuw team aan te maken.'
        },
        STOPPED: 'stopped'
    },
    ABOUT: {
        PARAGRAPH_1: 'De Harvest Coördinatie app is een eersteklas applicatie die uw telematicagegevens valoriseert.',
        PARAGRAPH_2:
            'In de eerste plaats helpt het enorm om de coördinatie tussen uw mensen en machines in zowel het veld als onderweg te verbeteren. In de tweede plaats biedt het u een unieke geo-traceerbaarheidstool van uw gewassen en gewasvoedingsparameters.',
        PARAGRAPH_3:
            'De APP is beschikbaar als GRATIS versie, maar om de volledige functieset te kunnen gebruiken heeft u het oogstcoördinatieapparaat (onderdeelnummer 12376543) nodig dat in uw combine(s) moet worden geïnstalleerd. Met het apparaat profiteert u van de voordelen van een efficiënt gebruik van uw big data.',
        PARAGRAPH_4:
            "Belangrijk! Om een poort aan te maken, klikt u met uw cursor op de kaart in het tabblad 'Overzicht' en houdt u ononderbroken 3 seconden ingedrukt. Om die poort vervolgens te verwijderen, volstaat het opnieuw op het Poort-icoontje op de kaart te klikken."
    },
    DASHBOARD: {
        ACTIVE_TEAMS: 'Actieve teams',
        WORKFORCE_USERS: 'Personeelsbestand',
        WORKFORCE_USERS_INFO:
            'Uw personeelsbestand bestaat uit alle personen die voor u kunnen werken. Deze personen kunnen elk van uw teams invoeren in de mobiele app (en veld\'s teamlid worden). Als u een persoon toevoegt, ontvangt hij een e-mail waarin hij over deze gebeurtenis wordt geïnformeerd.',
        INACTIVE: 'Inactieve',
        WORKFORCE_SERVICE_VEHICLES: 'Mijn service voertuigen',
        WORKFORCE_SERVICE_VEHICLES_INFO:
            'De servicevoertuigen die u bezit (als Farm Manager) of die u vaak gebruikt. Elk veld\'s teamlid kan elk van deze servicevoertuigen toevoegen aan een actief veldteam.',
        BIN_CAPACITY_UNIT: 'm³',
        WORKFORCE_ARABLE_VEHICLES: 'Mijn landbouwvoertuigen',
        WORKFORCE_ARABLE_VEHICLES_INFO:
            'De landbouwvoertuigen (combine, SPFH, balenpers) die u bezit (als bedrijfsmanager) of die u vaak gebruikt. Elk veld\'s teamlid kan elk van deze voertuigen toevoegen aan een actief veldteam.',
        WORKFORCE_GROWERS: 'Kwekers',
        WORKFORCE_GROWERS_INFO:
            'De kwekers van de velden die je hebt geoogst. U kunt ze uitnodigen via hun e-mailadres. Zij kunnen dan alleen de activiteit en de rapportage over hun vakgebied zien.',
        ADD: 'Toevoegen',
        NO_WORKFORCE: {
            PARAGRAPH_1: 'Er zijn 0 personen in uw personeelsbestand',
            PARAGRAPH_2:
                'Voeg uw eerste personeelslid toe, zodat hij/zij zich kan aansluiten bij één van uw teams in de mobiele app'
        },
        NO_FARM_BINS: {
            PARAGRAPH_1: 'Je hebt 0 servicevoertuigen opgeslagen.',
            PARAGRAPH_2: 'Voeg uw eerste servicevoertuig toe, zodat deze later aan een veldteam kan worden toegevoegd'
        },
        NO_FARM_ARABLE_VEHICLES: {
            PARAGRAPH_1: 'Je hebt 0 landbouwvoertuigen opgeslagen.',
            PARAGRAPH_2: 'Maak een team aan in de mobiele app en maak verbinding met uw eerste landbouwvoertuigen'
        },
        NO_GROWERS: {
            PARAGRAPH_1: 'Je hebt geen teams waarvoor "kweker" is opgegeven!',
            PARAGRAPH_2:
                'Wanneer u een bestaand team aanmaakt (of bewerkt) met het veld "Kweker" gespecificeerd, worden deze namen van kwekers beschikbaar zodat u ze kunt uitnodigen!'
        }
    },
    TEAM_MEMBERS: {
        COMBINES: 'Combine',
        SPFHS: 'SPFHs',
        BALERS: 'Balenpers',
        SERVICE_VEHICLES: 'Servicevoertuigen',
        USERS: 'Gebruikers',
        NO_COMBINES_IN_TEAM: 'Geen combine in het team',
        NO_BALERS_IN_TEAM: 'Geen balenpers in het team',
        NO_BINS_IN_TEAM: 'Geen Servicevoertuigen in het team',
        NO_USERS_IN_TEAM: 'Geen Gebruikers in het team',
        REMOVED: 'Verwijderd'
    },
    TEAM_PAGE: {
        FIELD_FINISHED_AT: 'Voorspeld einde van het veld:',
        TIME_UNTIL_BIN_FULL: 'Tijd totdat de bak vol is',
        LAST_SEEN: 'laatst gezien',
        ESTIMATED_TOTAL_BALE_COUNT: 'Voorspelde balentelling:',
        ESTIMATED_BINS_LEFT: 'Resterende bakken nodig:',
        WEIGHT_TO_FINISH: 'Resterend gewicht om te oogsten:',
        VOLUME_TO_FINISH: 'Resterend volume om te oogsten:',
        VOLUME_WEIGHT_TO_FINISH: 'Resterend volume/gewicht:'
    },
    CONTEXT_MENU: {
        DOWNLOAD_BIN_REPORT_PDF: 'Download bakrapport PDF',
        DOWNLOAD_BIN_REPORT_CSV: 'Download bakrapport CSV',
        HIDE_FIELD_COVERAGE: 'Velddekking verbergen',
        SHOW_FIELD_COVERAGE: 'Toon velddekking',
        SHOW_INFORMATION: 'Toon informatie',
        SHOW_ESP_CAMS: 'Toon realtime weergave'
    },
    TELEMETRY: {
        TITLE: 'Onmiddellijke informatie',
        COLUMN_VALUE_TITLE: 'Variabel',
        COLUMN_VARIABLE_TITLE: 'Waarde',
        loading: 'Data ophalen...',
        notAvailable: 'Sorry, er zijn momenteel geen gegevens beschikbaar!'
    },
    MODALS: {
        ADD_USER: {
            TITLE: 'Personeelslid toevoegen',
            FORM: {
                FIRST_NAME_LABEL: 'Voornaam',
                FIRST_NAME_ERROR_REQUIRED: 'Voornaam moet ingevuld zijn',
                FIRST_NAME_ERROR_LENGTH: 'Voornaam mag niet langer zijn dan 50 tekens',
                LAST_NAME_LABEL: 'Achternaam',
                LAST_NAME_ERROR_REQUIRED: 'Achternaam moet ingevuld zijn',
                LAST_NAME_ERROR_LENGTH: 'Achternaam mag niet langer zijn dan 50 tekens',
                EMAIL_LABEL: 'E-mail',
                EMAIL_ERROR_VALID: 'E-mail moet een geldig e-mailadres zijn',
                PHONE_LABEL: 'Telefoonnummer (bijv. +32494...)',
                PHONE_ERROR_VALID: 'Telefoonnummer is ongeldig',
                CONFIRM_BUTTON: 'Toevoegen'
            }
        },
        LINK_EDIT_GROWER: {
            TITLE_LINK: 'Koppeling kweker',
            TITLE_EDIT: 'Kweker bewerken',
            FORM: {
                GROWER_NAME: 'Naam van de kweker',
                FIRST_NAME_LABEL: 'Voornaam',
                FIRST_NAME_ERROR_REQUIRED: 'Voornaam moet ingevuld zijn',
                FIRST_NAME_ERROR_LENGTH: 'Voornaam mag niet langer zijn dan 50 tekens',
                LAST_NAME_LABEL: 'Achternaam',
                LAST_NAME_ERROR_REQUIRED: 'Achternaam moet ingevuld zijn',
                LAST_NAME_ERROR_LENGTH: 'Achternaam mag niet langer zijn dan 50 tekens',
                EMAIL_LABEL: 'E-mail',
                EMAIL_ERROR_VALID: 'E-mail moet een geldig e-mailadres zijn',
                EMAIL_ERROR_SAME_AS_FARM_MANAGER:
                    'Je kunt jezelf niet uitnodigen als kweker! Gebruik alsjeblieft een ander e-mailadres.',
                CONFIRM_BUTTON_LINK: 'Kweker koppelen',
                CONFIRM_BUTTON_EDIT: 'Kweker bewerken'
            }
        },
        CONFIRM_UNLINK_GROWER: {
            TITLE: 'Koppeling met kweker verbreken',
            MESSAGE: "Weet je zeker dat je de koppeling met deze kweker wilt verbreken? Hij zal geen toegang meer hebben tot de gegevens van zijn teams in het portaal."
        },
        ADD_BIN: {
            TITLE: 'Bak toevoegen',
            FORM: {
                NAME_LABEL: 'Naam',
                NAME_ERROR_REQUIRED: 'Naam moet ingevuld zijn',
                NAME_ERROR_LENGTH: 'Naam mag niet langer zijn dan 50 tekens',
                CAPACITY_LABEL: 'Capaciteit (in m³)',
                CAPACITY_ERROR_REQUIRED: 'Capaciteit moet ingevuld zijn',
                CAPACITY_ERROR_POSITIVE: 'Capaciteit moet een positief getal zijn',
                BIN_TYPE_LABEL: 'Type',
                BIN_TYPES: {
                    [BinTypeEnum.NORMAL_BIN]: 'Normale bak',
                    [BinTypeEnum.MOTHER_BIN]: 'Moederbak',
                    [BinTypeEnum.TRUCK]: 'Vrachtwagen',
                    [BinTypeEnum.CHASER_BIN]: 'Achtervolgbak'
                },
                BIN_COLOR_LABEL: 'Kleur',
                CONFIRM_BUTTON: 'Toevoegen'
            }
        },
        ADD_BALE_SERVICE_VEHICLE: {
            TITLE: 'Balen servicevoertuig toevoegen',
            FORM: {
                NAME_LABEL: 'Naam',
                NAME_ERROR_REQUIRED: 'Naam moet ingevuld zijn',
                NAME_ERROR_LENGTH: 'Naam mag niet langer zijn dan 50 tekens',
                BALE_SERVICE_VEHICLE_TYPE_LABEL: 'Type',
                BALE_SERVICE_VEHICLE_TYPES: {
                    // @ts-ignore
                    [BaleServiceVehicleTypeEnum.WRAPPER]: 'Wrapper',
                    // @ts-ignore
                    [BaleServiceVehicleTypeEnum.PICKUP]: 'Pickup'
                },
                BALE_SERVICE_VEHICLE_COLOR_LABEL: 'Kleur',
                CONFIRM_BUTTON: 'Toevoegen'
            }
        },
        ADD_SERVICE_VEHICLE: {
            TITLE: 'Servicevoertuigen toevoegen',
            BIN: 'bak',
            BALE_SERVICE_VEHICLE: 'balenwrapper of pickup'
        },
        CONFIRM_DELETE_USER: {
            TITLE: 'Gebruiker verwijderen',
            MESSAGE: 'Weet je zeker dat je deze gebruiker wilt verwijderen uit je personeelsbestand?'
        },
        CONFIRM_DELETE_BIN: {
            TITLE: 'Servicevoertuigen verwijderen',
            MESSAGE: 'Weet je zeker dat je dit servicevoertuigen uit je personeelsbestand wilt verwijderen?'
        },
        CONFIRM_DELETE_BALE_SERVICE_VEHICLE: {
            TITLE: 'Servicevoertuigen verwijderen',
            MESSAGE: 'Weet je zeker dat je dit servicevoertuigen uit je personeelsbestand wilt verwijderen?'
        },
        CREATE_GATE: {
            TITLE: 'Poort aanmaken',
            MESSAGE: 'Weet je zeker dat je een poort op deze locatie wilt aanmaken?'
        },
        DELETE_GATE: {
            TITLE: 'Poort verwijderen',
            MESSAGE: 'Weet je zeker dat je de poort op deze locatie wilt verwijderen?'
        },
        COMING_SOON: {
            TITLE: 'Binnenkort beschikbaar',
            MESSAGE: 'Deze functionaliteit is momenteel in ontwikkeling'
        },
        SETTINGS: {
            TITLE: 'Instellingen',
            FORM: {
                COMBINE_REPORT_TYPE_LABEL: 'Rapporttype (Combine)',
                SPFH_REPORT_TYPE_LABEL: 'Rapporttype (SPFH)',
                BALER_REPORT_TYPE_LABEL: 'Rapporttype (Balenpers)',
                WARN_PROXIMITY_METERS_LABEL: 'Waarschuwing voertuig nabijheid (in m)',
                LANGUAGE_LABEL: 'Taal',
                LANGUAGE_ERROR_REQUIRED: 'Taal moet ingevuld zijn',
                CONFIRM_BUTTON: 'Instellingen opslaan',
                BRANDING_LABEL: 'Merkidentiteit',
                LOADING: 'Instellingen ophalen van server',
                NOT_AVAILABLE:
                    "Instellingen konden niet worden opgehaald van de server. Controleer je internetverbinding of probeer het later opnieuw.",
                APDT_CREDENTIALS_LABEL: 'APDT inloggegevens',
                CONSENT_LABEL: 'Toestemming'
            }
        },
        EDIT_USER: {
            TITLE: 'Bewerk personeelslid',
            FORM: {
                EMAIL_LABEL: 'E-mailadres',
                TELEPHONE_NUMBER_LABEL: 'Telefoonnummer',
                ACTIVE_INACTIVE_LABEL: 'Actief',
                ACTIVE_INACTIVE_INFO_TEXT:
                    'Een inactieve gebruiker kan de mobiele applicatie niet gebruiken en kan niet deelnemen aan een van je veldteams.',
                AUTHORITY_LABEL: 'Bevoegdheid',
                AUTHORITY_INFO_TEXT:
                    "Een gebruiker met 'bevoegdheid' kan gedetailleerde machine- en gewasparameters zien. Hij/zij kan ook andere veldteamleden uit het team verwijderen.",
                CONFIRM_BUTTON: 'Opslaan'
            }
        },
        STOP_TEAM: {
            TITLE: (teamName: string) => `Stop team: ${teamName}`,
            MESSAGE:
                'Weet je zeker dat je dit team wilt stoppen? Deze actie kan niet ongedaan worden gemaakt. Het veldrapport wordt gegenereerd nadat het team is gestopt.'
        },
        DELETE_TEAM: {
            TITLE: (teamName: string) => `Team verwijderen: ${teamName}`,
            MESSAGE: 'Weet je zeker dat je dit team wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.'
        },
        COMBINE_FUEL_LOW: {
            TITLE: 'Laag brandstofniveau combine!',
            MESSAGE: (fuelTankLevel: number, combineName: string) =>
                `Let op! Het brandstofniveau voor ${combineName} is ${fuelTankLevel}%`
        },
        SPFH_FUEL_LOW: {
            TITLE: 'Laag brandstofniveau SPFH!',
            MESSAGE: (fuelTankLevel: number, spfhName: string) =>
                `Let op! Het brandstofniveau voor ${spfhName} is ${fuelTankLevel}%`
        },
        BALER_FUEL_LOW: {
            TITLE: 'Laag brandstofniveau balenpers!',
            MESSAGE: (fuelTankLevel: number, balerName: string) =>
                `Let op! Het brandstofniveau voor ${balerName} is ${fuelTankLevel}%`
        },
        CONFIRM_DELETE_FARM_BOUNDARY: {
            TITLE: 'Grens verwijderen',
            MESSAGE: 'Weet je zeker dat je deze grens wilt verwijderen?'
        },
        EDIT_TEAM_INFO: {
            TITLE: 'Teaminfo bewerken',
            CROP_ERROR_LENGTH: 'Gewasnaam mag niet langer zijn dan 50 tekens',
            OPERATOR_ERROR_LENGTH: 'Operator mag niet langer zijn dan 50 tekens',
            FARM_ERROR_LENGTH: 'Boerderijnaam mag niet langer zijn dan 50 tekens',
            FIELD_ERROR_LENGTH: 'Veldnaam mag niet langer zijn dan 50 tekens',
            GROWER_ERROR_LENGTH: 'Kweker mag niet langer zijn dan 50 tekens',
            NAME_ERROR_REQUIRED: 'Naam moet ingevuld zijn',
            YEAR_ERROR_REQUIRED: 'Jaar moet ingevuld zijn',
            YEAR_ERROR_FUTURE: 'Jaar kan niet in de toekomst liggen',
            YEAR_ERROR_MIN_YEAR: 'Jaar moet minstens 2000 zijn'
        },
        CONFIRM_IMPORT_FROM_EVO_PORTAL: {
            TITLE: 'Importeren vanuit EVO-portaal',
            MESSAGE: 'Wil je grenzen importeren vanuit het EVO-portaal? Dit zal bestaande geïmporteerde grenzen overschrijven.',
            LOADING_MESSAGE: 'De import is gestart. Je kunt dit venster veilig sluiten.'
        },


        CONFIRM_UPLOAD_TO_EVO_PORTAL: {
            TITLE: 'Uploaden naar EVO-portaal',
            CONFIRM: (target: string) => `Wil je deze ${target} uploaden naar het EVO-portaal?`,
            EXPORTING: (target: string) => `${target} wordt geüpload...`,
            FAILURE: (target: string) => `Uploaden van ${target} mislukt.`,
            SUCCESS: (target: string) =>
                `De upload is gelukt. Om de ${target} te gebruiken, moet je het bestand met de onderstaande naam verwerken in het EVO-portaal zelf.`,
            SHOW_HOW_TO_PROCESS: 'Toon hoe te verwerken',
            HOW_TO_PROCESS: {
                STEP_1: 'Ga naar het EVO-portaal',
                STEP_2: 'Klik op het tandwielpictogram.',
                STEP_3: 'Klik op het tabblad <strong>Data-bestanden</strong>.',
                STEP_4: (uploadedFileName: string) =>
                    `Klik op het bestand met de naam <strong>${uploadedFileName}</strong>.`,
                STEP_5: 'Klik op <strong>Verwerken</strong>.',
                STEP_6: 'Volg de stappen in de wizard.'
            }
        },
        MARKER: {
            TITLE: {
                PICTURE: 'Bekijk foto',
                OBSTACLE: 'Bekijk obstakel'
            },
            NAME: {
                LABEL: 'Naam'
            },
            LOCATION: {
                LABEL: 'Locatie'
            },
            CREATED_ON: {
                LABEL: 'Aangemaakt op'
            },
            NO_PICTURE: 'Er is geen foto genomen'
        },
        ESP_CAMS: {
            TITLE: 'Real-time weergave',
            NO_ESP_CAMS: 'Geen actieve camera\'s',
            FORM: {
                ESP_CAM: {
                    LABEL: 'Camera',
                    ITEM(mac: string): string {
                        return `Camera ${mac}`;
                    }
                }
            },
            ERROR_GET_ESP_CAMS: 'Ophalen van camera\'s is mislukt'
        }
    },
    MESSAGES: {
        URGENT_UNLOAD_NEEDED: 'Dringend lossen vereist',
        UNLOADING_IMMINENT: 'Lossen is nabij',
        DELAY: 'Ik zal laat zijn op het veld',
        LUNCH_DINNER_TIME: 'Lunch / diner tijd',
        YES: 'Ja',
        NO: 'Nee',
        CUSTOM: 'Aangepast bericht'
    },
    LANGUAGES: {
        [LanguageEnum.ENGLISH]: 'Engels',
        [LanguageEnum.DUTCH]: 'Nederlands',
        [LanguageEnum.FRENCH]: 'Frans'
    },
    COMBINE_TELEMETRY: {
        combineName: 'Naam van de combine',
        eventTime: 'Gebeurtenistijd',
        vehicleId: 'Voertuig-ID',
        relativeGraintankContent: 'Relatieve inhoud Graantank in %',
        grainVolumeInGraintank__m3: 'Graanvolume in de Graantank in m³',
        remainingTimeToFullGraintank__s: 'Resterende tijd tot 100% volle Graantank',
        grower: 'Kweker',
        farm: 'Boerderij',
        field: 'Veld',
        cropType: 'Gewas',
        drumrotor_speed_newholland__rpm: 'Trommelsnelheid',
        concavePosition: 'Positie trommelconcave',
        rotSepConcave: 'Rot. sep. concave',
        cleanFanSpd: 'Schoonblaassnelheid',
        upSievePosition: 'Bovenste zeef',
        loSievePosition: 'Onderste zeef',
        preSievePosition: 'Pre-zeef',
        chopper_speed__rpm: 'Snelheid gehakseld',
        engFuelRate: 'Brandstofverbruik motor',
        groundspeed__km_h: 'Grondsnelheid',
        grainBinLevel: 'Niveau graanbak',
        engineLoad: 'Motorbelasting',
        fuelLevel: 'Inhoud brandstoftank',
        instantaneousCropFlow: 'Capaciteit Graanbak',
        actualYield: 'Opbrengst',
        cropMoisture: 'Vochtigheid gewas',
        grainTemperature: 'Temperatuur graan',
        brokenGrainSummary: 'Gebroken graan / sensor',
        mogSummary: 'MOG / sensor',
        returnSummary: 'Rendement / sensor',
        sieveGrainLossSummary: 'Zeefverlies / sensor',
        separatorLossSummary: 'Verlies separator / sensor',
        dryMatterpc: 'Droge stof / Verse droge stof',
        crudeProteinpc: 'Droge stof / Verse ruw eiwit',
        starchpc: 'Droge stof / Verse zetmeel',
        ADFpc: 'Droge stof / Verse ADF',
        NDFpc: 'Droge stof / Verse NDF',
        ashpc: 'Droge stof / Verse as',
        crudeFatpc: 'Droge stof / Verse ruwe vetten'
    },
    SPFH_TELEMETRY: {
        spfhName: 'Naam van de SPFH',
        // Farm data
        GrowerData: 'Kweker',
        FarmData: 'Boerderij',
        FieldData: 'Veld',
        Operator: 'Operator',

        // Crop data
        Crop: 'Gewas',
        CropFlowDry: 'Gewasstroom Droog',
        CropFlowWet: 'Gewasstroom Nat',
        dryMatterpc: 'Droge stof / Verse droge stof',
        crudeProteinpc: 'Droge stof / Verse ruw eiwit',
        starchpc: 'Droge stof / Verse zetmeel',
        ADFpc: 'Droge stof / Verse ADF',
        NDFpc: 'Droge stof / Verse NDF',
        ashpc: 'Droge stof / Verse as',
        crudeFatpc: 'Droge stof / Verse ruwe vetten',

        // Machine data
        Machine: 'Machine',
        ProductIdModel: 'Product ID Model',
        ProductIdBrand: 'Product ID Merk',
        GroundSpeed: 'Grondsnelheid',
        Header_type: 'Header Type',
        WorkCondition: 'Werkconditie',
        Active_working_width: 'Actieve werkbreedte',
        EngineRpm: 'Motortoerental',
        Number_of_knives: 'Aantal messen',
        Loc: 'Lengte van snede (LOC)',
        MetalDetected: 'Metaal gedetecteerd',
        MetalPosition: 'Metaalpositie',
        SpoutRotPosition: 'Positie uitwerpopening',
        CpRollsOpening: 'Opening Cp Rollen',
        cpRollSpeedRatio: 'Verhouding snelheid Cp Rollen',
        FourWheelDrive: 'Vierwielaandrijving',
        SpoutDeflectorPosition: 'Positie uitwerpopening',
        EngineLoad: 'Motorbelasting',
        EngFuelRate: 'Brandstofverbruik motor',
        EngCoolantTemp: 'Koelvloeistoftemperatuur motor',
        FuelLevel: 'Brandstofniveau',
        UreaTankLevel: 'Niveau ureumtank',
        GreasingObstruction: 'Smeersel obstructie',
        SilageAdditivesTankLevel: 'Niveau tank silage-additieven',
        SilageAdditivesFlow: 'Stroom silage-additieven',
        HarvestSpeed: 'Oogstsnelheid',
        TargetId: 'Lossen naar doel',
        HarvestedVolumeToTarget: 'Gelost volume',
        Additive_setpoint_dosis: 'Additief instelpunt dosering'
    },
    BALER_TELEMETRY: {
        balerName: 'Naam van de balenpers',
        eventTime: 'Gebeurtenistijd',
        grower: 'Kweker',
        farm: 'Boerderij',
        field: 'Veld',
        operator: 'Operator',
        setPointLength: 'Lengte instelpunt',
        density: 'Dichtheid',
        cropCutter: 'Gewas snijder',
        engaged: 'Ingeschakeld',
        notEngaged: 'Niet ingeschakeld',
        plungerRatio: 'Plunjerverhouding'
    },
    BALER_LOAD_REPORT: {
        TITLE: 'Balenpers laadrapport',
        bale: 'Baal',
        balerName: 'Naam van de balenpers',
        creation: 'Creatie',
        weight: 'Gewicht',
        length: 'Lengte',
        moisture: 'Vochtigheid',
        capacity: 'Capaciteit',
        density: 'Dichtheid',
        plungerRatio: 'Plunjerverhouding',
        nrOfFlakes: 'Aantal flakes',
        cropCutter: 'Gewas snijder',
        engaged: 'Ingeschakeld',
        notEngaged: 'Niet ingeschakeld'
    },
    FIELD_REPORT: {
        TITLE: 'Veldrapport',
        DOWNLOAD_PDF: 'PDF downloaden',
        DOWNLOAD_CSV: 'CSV downloaden',
        loading: 'Ophalen van veldrapport...',
        teamName: 'Team',
        time: 'Tijd',
        year: 'Jaar',
        startTime: 'Start',
        endTime: 'Einde',
        growerData: 'Kweker',
        farmData: 'Boerderij',
        fieldData: 'Veld',
        operator: 'Operator',
        load__m3: 'Totale lading',
        actlYield__kg_ha: 'Gemiddelde opbrengst',
        current_Crop: 'Gewas',
        avgLoc__mm: 'Gemiddelde LOC',
        cropDry__ton: 'Totale gewas droog',
        cropWet__ton: 'Totale gewas nat',
        combines: 'Combines',
        spfhs: 'SPFHs',
        machine: 'Machine',
        productIdBrand: 'Merk',
        headerType: 'Type header',
        workWidthLength__m: 'Werkbreedte (in m)',
        workWidthNrOfRows: 'Werkbreedte (nr of rijen)',
        avgEngineLoad: 'Gemiddelde motorbelasting',
        avgFuelRate__l_h: 'Gemiddeld brandstofverbruik',
        totalFuelConsumption: 'Totaal brandstofverbruik',
        avgCpRollOpening__mm: 'Gemiddelde CP-roll-opening',
        avgCpRollSpeedRatio: 'Gemiddelde CP-roll-snelheidsverhouding',
        diffSilageAdditivesTankLevel: 'Verschil silage-additieven tankniveau',
        totalSilageAdditivesConsumption__l: 'Totaal silage-additievenverbruik',
        sharpenings: 'Slijpprocedures',
        firstMetalDetected: 'Eerste keer metaal gedetecteerd',
        firstGreasingObstruction: 'Smeermiddel obstructie',
        bins: 'Servicevoertuigen',
        teamMembers: 'Teamleden',
        currentCrop: 'Gewas',
        fuelConsumption: 'Brandstofverbruik',
        cropMoist: 'Gemiddelde gewasvochtigheid',
        grainTemp: 'Gemiddelde graantemperatuur',
        brokenGrain: 'Gemiddeld gebroken graan',
        mog: 'Gemiddelde MOG',
        returns: 'Gemiddelde Returns',
        sieveLoss: 'Gemiddeld zeefverlies',
        separatorLoss: 'Gemiddeld separatorverlies',
        dry_matter: 'Gemiddelde Droge/Fresh DryMatter',
        starch: 'Gemiddelde Droge/Fresh Zetmeel',
        crude_protein: 'Gemiddelde Droge/Fresh Ruw Eiwit',
        acid_detergeant_fiber: 'Gemiddelde Droge/Fresh ADF',
        neutral_detergean_fiber: 'Gemiddelde Droge/Fresh NDF',
        ash: 'Gemiddelde Droge/Fresh As',
        crude_fat: 'Gemiddelde Droge/Fresh Ruw Vet',
        totalNumberOfBales: 'Totaal aantal balen',
        avgLengthOfBales__m: 'Gemiddelde lengte van balen',
        avgMoisture: 'Gemiddelde vochtigheid van balen',
        avgWeight__kg: 'Gemiddeld gewicht van balen',
        totalWeight__kg: 'Totaal gewicht van balen',
        balerCapacity__bale_hr: 'Capaciteit van de balenpers',
        avgPlungerRatio: 'Gemiddelde plunjerverhouding',
        operational_data: 'Operationele gegevens:',
        crop_data: 'Gewasgegevens:',
        machine_data: 'Machinegegevens:',
        firstShearboltFlywheelAlarm: 'Eerste keer Shearbolt Flywheel Alarm',
        firstShearboltNeedleYokeAlarm: 'Eerste keer Shearbolt Needle Yoke Alarm',
        firstShearboltStufferAlarm: 'Eerste keer Shearbolt Stuffer Alarm',
        firstMajorPickupSlipAlarm: 'Eerste keer Major Pickup Slip Alarm',
        engine_load: 'Motorbelasting',
        drumrotor_speed_newholland__rpm: 'Trommelsnelheid',
        concave_position__mm: 'Positie trommelconcave',
        rotor_separation_concave: 'Rot. sep. concave',
        clean_fan_speed__rpm: 'Schoonblaassnelheid',
        upper_sieve_position__mm: 'Bovenste zeefpositie',
        lower_sieve_position__mm: 'Onderste zeefpositie',
        presieve_position__mm: 'Pre-zeefpositie'
    },
    BOUNDARIES: {
        TITLE: 'Grenzen',
        SUBTITLE:
            'Grenzen zijn de contouren van je velden. Velden met grenzen bieden meer (realtime) statistieken (bijv. tijd tot voltooiing)',
        SEARCH: {
            PLACEHOLDER: 'Zoek bijv. tarwe, baudoin, ...'
        },
        TYPES: {
            EVO_PORTAL: 'EVO portaal',
            HISTORICAL: 'Historische veldbedekking',
            MANUAL: 'Handmatig aangemaakt',
            TEAM: 'Aangemaakt in team'
        },
        TABLE: {
            HEADERS: {
                NAME: 'Naam',
                CROP: 'Gewas',
                CREATED: 'Aangemaakt',
                FIELD_SIZE: 'Oppervlakte',
                TYPE: 'Bron'
            }
        },
        FEATURES: {
            DIGIFARM: 'Gebruik Digifarm',
            SENTINEL: 'Gebruik Sentinel',
            SYNC_DATA_ON_CREATE: 'Importeer historische data'
        },
        CREATE: 'Nieuwe grens creëren',
        ACREAGE: 'Oppervlakte',
        SAVE: 'Grens opslaan',
        FORM: {
            NAME_LABEL: 'Naam',
            NAME_ERROR_REQUIRED: 'Naam moet ingevuld zijn',
            NAME_ERROR_LENGTH: 'Naam mag niet langer zijn dan 50 tekens',
            CONFIRM_BUTTON: 'Toevoegen'
        }
    },
    EVO_PORTAL: {
        UNAVAILABLE: 'Alleen beschikbaar wanneer ingelogd met een EVO portaal account',
        IMPORT: 'Importeren vanuit EVO portaal',
        EXPORT: 'Exporteren naar EVO portaal'
    },
    POLYGON_MAP: {
        START_DRAWING: 'Begin met tekenen',
        REMOVE_DRAWING: 'Tekening verwijderen',
        STOP_DRAWING: 'Stop met tekenen',
        SELECT_BOUNDARY: 'Zoom in en selecteer een grens.',
        DRAW_TEXT: 'Als je grens niet beschikbaar is, kun je',
        DRAW_ACTION: 'tekenen'
    },
    MAP: {
        TEAM_STOPPED: 'Team gestopt',
        VIEWING_AS_GROWER: 'Alleen bekijken'
    },
    ADMIN: {
        VEHICLE: 'Voertuig',
        API_MANAGEMENT: 'API-beheer',
        MAC_ADDRESS: 'Mac-adres',
        LOGS: 'Logs',
        START_UPLOADING_LOGS: 'Start nieuwe upload',
        UPLOAD_STATUS: {
            NEVER_REQUESTED: 'Er zijn nog geen logs geüpload',
            IN_PROGRESS: 'Poging tot uploaden van logs',
            SUCCEEDED: 'Logs zijn succesvol geüpload',
            FAILED: (failureMessage: string) => `Uploaden van logs mislukt: ${failureMessage}`
        },
        LINK: 'koppelen',
        FORBIDDEN: 'Je bent geen beheerder',
        LINK_TO_STORAGE: 'Ga naar opslagaccount',
        THIRD_PARTY: {
            TITLE: 'Derde partij gegevensconsumenten'
        }
    },
    TEAM_PICKER: {
        SORT: {
            BY_TIME: 'Sorteren op tijd',
            ALPHABETICALLY: 'Alfabetisch sorteren'
        },
        ERROR: {
            NO_TEAM: 'Geen team',
            CREATE_TEAM: ' - gelieve een team aan te maken op de mobiele app',
        },
    },
    APDT_ADVICE: {
        TITLE: 'Advies',
        NEW_ADVICE: 'Nieuw advies',
        SHOW_DATA: 'Toon bronnen',
        NO_PREV_ADVICES: 'Geen eerdere adviezen gegenereerd',
        PREV_ADVICES: 'Vorige adviezen',
        PREV_ADVICES_TABLE: {
            ACTION: 'Actie',
            CROP: 'Gewas',
            CREATED: 'Aangemaakt'
        },
        ACTIONS: {
            SEEDBED_PREPARATION: 'Voorbereiding van het zaaibed',
            LIMING: 'Bekalking',
            SOWING: 'Zaaien',
            FUSARIUM: 'Fusarium',
            APHID: 'Aphid',
            OULEMA: 'Oulema',
            FERTILIZING_N1: 'Bemesting N1',
            FERTILIZING_N2: 'Bemesting N2',
            FERTILIZING_N3: 'Bemesting N3',
            FERTILIZING: 'Bemesting',
            CROP_PROTECTION: 'Gewasbescherming',
            HARVESTING: 'Oogsten'
        },
        CROPS: {
            POTATOES: 'Aardappelen',
            WINTER_WHEAT: 'Winter tarwe'
        },
        DETAIL: {
            TITLE: 'Detailadvies',
            MANUAL_INPUT: 'Handmatige invoer',
            SENSOR_DATA: 'Sensordata',
            MOTIVATION: 'Motivatie',
        },
        SOIL_TYPES: {
            CLAY: 'Klei',
            SAND: 'Zand',
            SANDY_LOAM: 'Zandleem',
            LOAM: 'Leem'
        },
        CULTIVAR_TYPES: {
            STANDARD: 'Standaard',
            HYBRID: 'Hybride'
        },
        FORMS: {
            ACTIONS: {
                NEXT: 'Volgende',
                PREV: 'Vorige',
                SUBMIT: 'Advies genereren'
            },
            ACTION_LABEL: 'Selecteer activiteit',
            SOURCE_HELP: 'Selecteer alle bronnen die je in het advies wilt opnemen.',
            SOWING: {
                THOUSAND_KERNEL_WEIGHT: 'Duizendkorrelgewicht',
                SOWING_TIME: 'Zaaitijd',
                CULTIVAR_TYPE: 'Cultivar type'
            },
            FUSARIUM: {
                PRECEDING_RISK: 'Voorafgaand risico',
                VARIETY_RISK: 'Variëteitsrisico',
            },
            LIMING: {
                PH_LAB: 'PH Lab',
                SOIL_TYPE: 'Bodemtype',
                LIMING_RECOMMENDATION: 'Bekalkingsadvies',
                ZBW_LIME: 'Neutraliserende waarde'
            },
            FERTILIZING_N1: {
                ADVICE_N1: 'N1 advies',
                N_CONTENT_FERTILIZER: 'N-waarde kunstmest',
                HOMOGENIZE: 'Homogeniseer',
            },
            FERTILIZING_N2: {
                ADVICE_N2: 'N2 advies',
                ADVICE_N3: 'N3 advies',
                N_CONTENT_FERTILIZER: 'N-waarde kunstmest',
                HOMOGENIZE: 'Homogeniseer',
                ADD_N3: 'Hoeveel van de 3de fractie wil je toevoegen?',
            },
            FERTILIZING_N3: {
                ADVICE_N3: 'N3 advies',
                N_CONTENT_FERTILIZER: 'N-waarde kunstmest',
                HOMOGENIZE: 'Homogeniseer',
            },
            SOURCE: {
                LABEL: 'Selecteer bron'
            },
        },
        HIDDEN_LAYERS: 'De volgende gegevens kunnen ook worden gebruikt voor een advies met hogere precisie.\n' +
            'Als je geïnteresseerd bent, neem dan contact op met je CNH-dealer.',
        LAYERS: {
            SOURCES: {
                GEOPROSPECTOR_ECA: 'Geoprospector ECa',
                AUGMENTA_INDEX: 'Augmenta',
                SOIL_ANALYSIS: 'Bodemanalyse',
                AGROPLANNING_YIELD: 'Agroplanning',
                AGDNA_CN1: 'AgDNA',
                WEATHERSTACK_WEATHER: 'Weer',
                WEATHERSTACK_FORECAST: 'Voorspelling',
                DIGIFARM_IMAGERY: 'Deep Resolution Imagery',
                ACTIONS: {
                    MERGE: 'Samenvoegen'
                }
            },
            OPTIONS: {
                GEOPROSPECTOR_ECA_1: 'ECa 1',
                GEOPROSPECTOR_ECA_2: 'ECa 2',
                GEOPROSPECTOR_ECA_3: 'ECa 3',
                GEOPROSPECTOR_ECA_4: 'ECa 4',
                AUG_INDEX: 'Augmenta-index',
                AGROPLANNING_YLD: 'Opbrengst',
                AGROPLANNING_YLD_FLOW: 'Opbrengst Flow',
                SOIL_ANALYSIS_PH: 'PH',
                SOIL_ANALYSIS_TEMP: 'Temperatuur',
                SOIL_ANALYSIS_MOIS: 'Vochtigheid',
                SOIL_ANALYSIS_EC: 'Elektrische geleidbaarheid',
                SOIL_ANALYSIS_N: 'Stikstof',
                SOIL_ANALYSIS_PHOS: 'Fosfor',
                SOIL_ANALYSIS_POT: 'Kalium',
                SOIL_ANALYSIS_SOIL: 'Bodem',
                AGDNA_CN1_WIDTH: 'Breedte',
                AGDNA_CN1_PROTEIN: 'Eiwit',
                AGDNA_CN1_CROP_FLOW: 'Gewasstroom',
                AGDNA_CN1_MOISTURE: 'Vochtigheid',
                WEATHERSTACK_WEATHER: 'Weer',
                WEATHERSTACK_FORECAST: 'Weersvoorspelling',
                NDVI: 'NDVI',
                EVI: 'EVI',
                NDMI: 'NDMI',
                MI: 'MI',
                BSI: 'BSI',
            },
            STYLES: {
                ZONE_ONE: '1 zone',
                ZONE_TWO: '2 zones',
                ZONE_THREE: '3 zones',
                ZONE_FOUR: '4 zones',
                ZONE_FIVE: '5 zones'
            }
        },
        WEATHER_WIDGET: {
            LAST_WEEK: '-7d'
        },
        WEATHER_CODES: {
            DAY: {
                113: 'Zonnig',
                116: 'Gedeeltelijk bewolkt',
                119: 'Bewolkt',
                122: 'Geheel bewolkt',
                143: 'Mist',
                176: 'Plaatselijke regen in de omgeving',
                179: 'Plaatselijke sneeuw in de omgeving',
                182: 'Plaatselijke natte sneeuw in de omgeving',
                185: 'Plaatselijke onderkoelde motregen in de omgeving',
                200: 'Onweersbuien in de omgeving',
                227: 'Driftsneeuw',
                230: 'Sneeuwstorm',
                248: 'Mist',
                260: 'Aanvriezende mist',
                263: 'Plaatselijke lichte motregen',
                266: 'Lichte motregen',
                281: 'Onderkoelde motregen',
                284: 'Zware onderkoelde motregen',
                293: 'Plaatselijke lichte regen',
                296: 'Lichte regen',
                299: 'Nu en dan normale regen',
                302: 'Normale regen',
                305: 'Nu en dan zware regen',
                308: 'Zware regen',
                311: 'Lichte onderkoelde regen',
                314: 'Moderate or Heavy freezing rain',
                317: 'Light sleet',
                320: 'Moderate or heavy sleet',
                323: 'Patchy light snow',
                326: 'Light snow',
                329: 'Patchy moderate snow',
                332: 'Moderate snow',
                335: 'Patchy heavy snow',
                338: 'Heavy snow',
                350: 'Ice pellets',
                353: 'Light rain shower',
                356: 'Moderate or heavy rain shower',
                359: 'Torrential rain shower',
                362: 'Light sleet showers',
                365: 'Moderate or heavy sleet showers',
                368: 'Light snow showers',
                371: 'Moderate or heavy snow showers',
                374: 'Light showers of ice pellets',
                377: 'Moderate or heavy showers of ice pellets',
                386: 'Patchy light rain in area with thunder',
                389: 'Moderate or heavy rain in area with thunder',
                392: 'Patchy light snow in area with thunder',
                395: 'Moderate or heavy snow in area with thunder',
            },
        },
    },
    AGDNA_CN1: {
        TITLE: 'AgDNA',
        SUBTITLE:
            'Importeer een cn1-bestand komende uit de machine of uw AgDNA-account om te gebruiken voor het adviesgeneratie.',
        ADD_FILE: 'CN1 bestand toevoegen',
        SEARCH: {
            PLACEHOLDER: 'Selecteer een bestand om te uploaden.',
            NO_ZIP_FILE: 'Upload mislukt. Probeer het opnieuw met een cn1-zipbestand.',
        },
        STATUS: {
            PENDING: 'Te verwerken',
            PROCESSING: 'Bezig met verwerken',
            COMPLETE: 'Verwerkt',
            FAILED: 'In fout'
        },
        TABLE: {
            FILENAME: 'Bestandsnaam',
            UPLOADED_ON: 'Opgeladen op',
            STATUS: 'Status',
        }
    }
}

export default NL_TRANSLATIONS;


//BIN = bak
//Workforce = Personeelsbestand (=> moet mss ploeg zijn?)
//Farm = Boerderij
//FARM_MANAGER: 'Bedrijfsleider',
//Harvest Coordination device: oogstcoördinatieapparaat
//gate: poort
//field team member: veld's teamlid
//arable vehicles: landbouwvoertuigen
//workforce member: personeelslid
//Combine Harvester, SPFH, Baler : combine (niet maaidorser), SPFH, balenpers
//service vehicles: servicevoertuigen
//field team : veldteam
//Grower: kweker
//bale wrapper or pickup: balen servicevoertuig
