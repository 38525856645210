import { render, staticRenderFns } from "./AdviceFertilizingN2Input.form-item.vue?vue&type=template&id=6d153ba8&scoped=true"
import script from "./AdviceFertilizingN2Input.form-item.vue?vue&type=script&lang=js"
export * from "./AdviceFertilizingN2Input.form-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d153ba8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VTextField})
