

export function maxRule(max: number, message: String|undefined){
    return (value: String): boolean | String => {
        let inputNumber = parseFloat(value?.trim());
        if (value && !isNaN(inputNumber) && inputNumber <= max) return true;
        return message !== undefined ? message : ("Input cannot be bigger than " + max);
    }
}

export function minRule(min: number, message: String|undefined){
    return (value: String): boolean | String => {
        let inputNumber = parseFloat(value?.trim());
        if (value && !isNaN(inputNumber) && min <= inputNumber) return true;
        return message !== undefined ? message : ("Input cannot be smaller than " + min);
    }
}

export const positiveNumberRule = minRule(0, "Number must be positive")

export function integerRule(message: String|undefined){
    const numberRegex = /^[+-]?\d+$/;
    return (value: String): boolean | String => {
        if(!numberRegex.test(value.toString())){
            return "Only integer numbers are allowed"
        }
        let inputNumber = parseInt(value?.trim());
        if (value && !isNaN(inputNumber) && numberRegex.test(value.toString())) return true;
        return message !== undefined ? message : ("Input must be an integer value");
    }
}

export const percentageRule = [
    integerRule(undefined),
    minRule(0, undefined),
    maxRule(100, undefined)
];

export function floatingPointRule(message: String|undefined){
    const floatingPointRegex = /^[+-]?\d+(?:\.\d+)?$/;
    return (value: String): boolean | String => {
        if (value.includes(",")){
            return "Use a point ( . ) for decimals"
        }
        if (!floatingPointRegex.test(value.toString())){
            return "Only floating point number are allowed"
        }
        let inputNumber = parseFloat(value?.trim());
        if (value && !isNaN(inputNumber)) return true;
        return message !== undefined ? message : ("Input must be a floating point value");
    }
}

export function requiredRule(message: String| undefined){
    return value => {
        if (value) return true;
        return "Required";
    }
}
